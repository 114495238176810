<template>
  <header class="sticky-top">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <a href="https://www.medialivesystem.com" target="_blank">
          <img :src="require('@/assets/images/logo-Medialive.png')" width="170" />
        </a>
        <!-- Hamburger button -->
        <button
          v-if="isLogged"
          id="navbarBtnToggle"
          class="navbar-toggler custom-navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainNavBar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Main Navigation -->
        <div
          v-if="isLogged"
          class="navbar-collapse collapse justify-content-center"
          id="mainNavBar"
        >
          <ul class="navbar-nav custom-navbar-nav mx-auto">
            <li class="nav-item" v-for="link in menuData" :key="link.label">
              <router-link
                v-if="showMenu(link)"
                @click.prevent="hideOffNavbarBtnToggle()"
                class="nav-link"
                :title="link.label"
                :alt="link.label"
                :to="
                  !link.params
                    ? { name: link.pathName }
                    : { name: link.pathName, params: { id: link.params.id } }
                "
                exact
                >{{ link.label }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'view-edit-account' }" exact
                ><div class="d-flex">
                  <i
                    class="zmdi zmdi-account"
                    style="font-size: 18px; line-height: 22px"
                  ></i>
                  <div
                    class="ms-2 text-primary"
                    style="line-height: initial; margin-top: 2px !important"
                  >
                    {{ this.$store.getters.getToken.name }}
                  </div>
                </div></router-link
              >
            </li>
            <li class="nav-item">
              <a href="/logout" @click.prevent="logout" class="nav-link d-flex">
                <div class="d-flex">
                  <i
                    class="zmdi zmdi-sign-in"
                    style="transform: rotate(180deg); font-size: 18px; line-height: 22px"
                  ></i>
                  <div
                    class="ms-2 text-primary"
                    style="line-height: initial; margin-top: 2px !important"
                  >
                    LOGOUT
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import menuData from "@/data/top_main_menu.json";

export default {
  components: {},
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      menuData,
      authData: {},
      isAdmin: false,
    };
  },
  computed: {
    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
  },
  methods: {
    pushAccount() {
      this.$router.push({
        name: "view-edit-account",
        params: { id: this.$store.getters.getToken.userId },
      });
    },
    showMenu(link) {
      this.isAdmin = this.$store.getters.getToken.isAdmin;
      if (this.isAdmin) {
        return true;
      } else if (!this.isAdmin) {
        if (
          (this.isAdmin && link.viewOnlyAdmin) ||
          (!this.isAdmin && !link.viewOnlyAdmin)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkIsAdmin() {
      let user = localStorage.getItem("AuthSessionData");
      if (user) {
        try {
          this.authData = JSON.parse(user);
          console.log("** Header >> load user from localStorage", this.authData);
          if (this.authData.isAdmin) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.error(
            "** Header, error during loading user data from localStorage >>",
            e
          );
          return false;
        }
      } else {
        console.log(
          "** Header >> load user data from localStorage, localStorage is empty"
        );
        return false;
      }
    },
    hideOffNavbarBtnToggle() {
      let navbarBtnToggle = document.getElementById("navbarBtnToggle");
      if (!navbarBtnToggle.classList.contains("collapsed") && this.width <= 991) {
        navbarBtnToggle.click();
      }
    },
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      let navbarBtnToggle = document.getElementById("navbarBtnToggle");
      if (!navbarBtnToggle.classList.contains("collapsed") && this.width > 991) {
        navbarBtnToggle.click();
      }
    },
    logout() {
      this.$store.dispatch("setVideosOrder", "3");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      this.$root.addSnackBarMsg("Logout successful!", "info");
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
