<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <!-- Title section -->
            <h1>
              IMAGE
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-edit"></i> </span>Resize
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Linear Loader -->
      <div v-if="isLoading" class="linear-loader">
        <span></span>
      </div>
      <!-- Content -->
      <div v-if="isVisible" class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Title -->
        <div class="row m-0 padding-x-4px">
          <div class="col-md-12 p-0 mt-4">
            <h4 class="color-mainblue m-0 p-0">
               {{ imageMetadataDetails_en.metaDataDetails.title }} 
            </h4>
          </div>
          <hr class="sepGray" />
        </div>
        <!-- Top Tabs -->
        <div class="row m-0 padding-x-4px">
          <div v-if="showAlertResize" class="size-info">
            <p>width : {{ imageWidth }}</p>
            <p>height : {{ imageHeight }}</p>
          </div>
          <div ref="KonvaContainer" class="konva-container" :style="{ height: imageHeight+60 + 'px' }"></div>
          <p class="color-mainblue">width : {{ imageWidth }}</p>
          <p class="color-mainblue">height : {{ imageHeight }}</p>
          <p></p>
          <hr class="sepGray" />
          <!-- Save Image Obj -->
          <div class="container-fluid p-0 my-3">
            <button
              @click="SaveImage()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span> <i class="zmdi zmdi-floppy"></i>Save Image </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import Konva from 'konva';
export default {
    data() {
        return {
            isChangesSaved: [],
            isLoading: false,
            isVisible: true,
            imgOriginalSrc: "https://konvajs.org/assets/yoda.jpg",
            imageId: "",
            awaitImage: [],
            image: [],
            imageWidth: 0,
            imageHeight: 300,
            imageMetadataDetails_en: {
                lang: "en",
                metaDataDetails: {
                title: "",
                author: "",
                s_abstract: "",
                eyelet: "",
                description: "",
                },
            },
            na: "",
            spinnerLoader: null,
            showAlertResize: false
        }
    },
    computed: {
        imageObj1() {
            const imageObj = new Image();
            imageObj.onload = () => {
            // Do something when the image is loaded
            };
            imageObj.src = this.imgOriginalSrc;
            return imageObj;
        },
    },
    methods:{
        // get Image Data From API
        async getImageDataFromAPI() {
            //Get image from API
            this.imageId = this.$route.params.id;
            this.isLoading = true;
            this.awaitImage = await EventService.getImage(
                this.imageId,
                this.$store.getters.getToken.token
            );

            if (this.awaitImage.status != 200) {
                console.error("Image >> id:" + this.imageId + " error during loading data");
                this.isLoading = false;
                this.$root.addSnackBarMsg(
                "GET IMAGE: An error has occurred, please try again",
                "error"
                );
                return;
            }
            this.image = this.awaitImage.data;
            this.getImgOriginalSrc();
            this.getAllMetadataDetails();
            this.isLoading = false;
            this.isVisible = true;
        },

        // get Img Original Src
        getImgOriginalSrc() {
            if (this.image.originalImageUri) {
                this.imgOriginalSrc =
                this.image.originalImageUri + "?" + Math.floor(Date.now() / 1000);
            } else {
                this.imgOriginalSrc = require("@/assets/images/image-placeholder.png");
            }
            this.initializeKonva();
        },

        // update Image Resize
        updateImageResize(activeAnchor) {
            if(this.showAlertResize){
                this.showAlertResize = true;
            }else{
                this.showAlertResize = true;
                setTimeout(() => {
                this.showAlertResize = false;
                }, 5000);
            }
            
            const group = activeAnchor.getParent();
            const topLeft = group.findOne('.topLeft');
            const topRight = group.findOne('.topRight');
            const bottomRight = group.findOne('.bottomRight');
            const bottomLeft = group.findOne('.bottomLeft');
            const image = group.findOne('Image');
            const anchorX = activeAnchor.x();
            const anchorY = activeAnchor.y();

            // updateImageResize anchor positions
            switch (activeAnchor.getName()) {
            case 'topLeft':
                topRight.y(anchorY);
                bottomLeft.x(anchorX);
                break;
            case 'topRight':
                topLeft.y(anchorY);
                bottomRight.x(anchorX);
                break;
            case 'bottomRight':
                bottomLeft.y(anchorY);
                topRight.x(anchorX);
                break;
            case 'bottomLeft':
                bottomRight.y(anchorY);
                topLeft.x(anchorX);
                break;
            }

            image.position(topLeft.position());
            const newWidth = topRight.x() - topLeft.x();
            const newHeight = bottomLeft.y() - topLeft.y();
            if (newWidth && newHeight) {
            this.imageWidth=newWidth;
            this.imageHeight=newHeight;
            image.width(newWidth);
            image.height(newHeight);
            }
        },
        
        // add Anchor for resize image
        addAnchor(group, x, y, name) {
            const anchor = new Konva.Circle({
            x: x,
            y: y,
            stroke: '#666',
            fill: '#ddd',
            strokeWidth: 2,
            radius: 8,
            name: name,
            draggable: true,
            dragOnTop: false,
            });

            anchor.on('dragmove', () => {
            this.updateImageResize(anchor);
            });
            anchor.on('mousedown touchstart', () => {
            group.draggable(false);
            anchor.moveToTop();
            });
            anchor.on('dragend', () => {
            group.draggable(true);
            });
            // add hover styling
            anchor.on('mouseover', () => {
            document.body.style.cursor = 'pointer';
            anchor.strokeWidth(4);
            });
            anchor.on('mouseout', () => {
            document.body.style.cursor = 'default';
            anchor.strokeWidth(2);
            });

            group.add(anchor);
        },

        // initialize Konva for image resize
        initializeKonva() {
            const tempImage = new Image();
            tempImage.src = this.imgOriginalSrc;
            this.showLoader(true);
            tempImage.onload = () => {
            this.showLoader(false);
            this.imageWidth = tempImage.naturalWidth;
            this.imageHeight = tempImage.naturalHeight;
            
            const darthVaderImg = new Konva.Image({
            width: this.imageWidth,
            height: this.imageHeight,
            });

            const darthVaderGroup = new Konva.Group({
            x: 10,
            y: (this.imageHeight+50) / 2 - (darthVaderImg.height()) / 2,
            draggable: true,
            });

            const layer = new Konva.Layer();
            const stage = new Konva.Stage({
            container: this.$refs.KonvaContainer,
            width: this.$refs.KonvaContainer.clientWidth,
            height: this.imageHeight +150,
            });

            darthVaderImg.image(this.imageObj1);

            layer.add(darthVaderGroup);
            darthVaderGroup.add(darthVaderImg);
            this.addAnchor(darthVaderGroup, 0, 0, 'topLeft');
            this.addAnchor(darthVaderGroup,  this.imageWidth, 0, 'topRight');
            this.addAnchor(darthVaderGroup,  this.imageWidth, this.imageHeight, 'bottomRight');
            this.addAnchor(darthVaderGroup, 0, this.imageHeight, 'bottomLeft');

            stage.add(layer);
            };
        },

        // Save Image
       async SaveImage(){
        this.canvasPic = document.createElement("canvas");
        this.canvasPic.id = "canvasPic";
        this.canvasPic.width = this.imageWidth;
        this.canvasPic.height = this.imageHeight;
        this.canvasPic.hidden = true;
        const renderedImage = this.canvasPic.toDataURL();
            if (renderedImage) {
                let imageObj = {
                data: renderedImage,
                width: this.imageWidth,
                height: this.imageHeight,
                imageId: this.image.imageId,
                };

                this.showLoader(true);
                let saveImage = await EventService.saveNewResolution(
                imageObj,
                this.$store.getters.getToken.token
                );
                this.showLoader(false);

                if (saveImage.status != 200) {
                console.error("Save new version >> error during saving data");
                this.$root.addSnackBarMsg(
                    "SAVE NEW VERSION: An error has occurred, please try again",
                    "error"
                );
                return;
                }

                this.$root.addSnackBarMsg("The new image version has been saved", "info");
                //update versions list
                this.getImageDataFromAPI();
            } else {
                this.$root.addSnackBarMsg("There is no image to save", "warning");
            }
        },

        // get All Metadata Details
        getAllMetadataDetails() {
            this.imageMetadataDetails_en = this.getMetadatadetailsByLang(
                this.image.metadata,
                "lang",
                "en",
                "metaDataDetails"
            );
        },

        // get Metadata details By Lang
        getMetadatadetailsByLang(videoObjKey, lang, value, metaDetails) {
            let metaDataDetails = {
                title: this.na,
                author: this.na,
                s_abstract: this.na,
                eyelet: this.na,
                description: this.na,
            };
            const searchMetaDataDetails = this.searchJsonArrayValueFromKey(
                videoObjKey,
                lang,
                value,
                metaDetails
            );

            if (searchMetaDataDetails.length == 0)
                return { lang: value, metaDataDetails: metaDataDetails };

            metaDataDetails = {
                title:
                searchMetaDataDetails[0].value.title != ""
                    ? searchMetaDataDetails[0].value.title
                    : this.na,
                author:
                searchMetaDataDetails[0].value.author != ""
                    ? searchMetaDataDetails[0].value.author
                    : this.na,
                s_abstract:
                searchMetaDataDetails[0].value.s_abstract != ""
                    ? searchMetaDataDetails[0].value.s_abstract
                    : this.na,
                eyelet:
                searchMetaDataDetails[0].value.eyelet != ""
                    ? searchMetaDataDetails[0].value.eyelet
                    : this.na,
                description:
                searchMetaDataDetails[0].value.description != ""
                    ? searchMetaDataDetails[0].value.description
                    : this.na,
            };

            return { lang: value, metaDataDetails: metaDataDetails };
        },

        // search Json Array Value From Key
        searchJsonArrayValueFromKey(jsonKey, key, valueToSearch, keyToGet) {
            let obj = [];
            for (let i = 0; i < jsonKey.length; i++) {
                for (const [_key, _value] of Object.entries(jsonKey[i])) {
                if (_key == key && _value == valueToSearch) {
                    for (const [_keyN, _valueN] of Object.entries(jsonKey[i])) {
                    if (_keyN == keyToGet) {
                        obj.push({ key: _keyN, value: _valueN });
                        break;
                    }
                    }
                    break;
                }
                }
            }
            return obj;
        },

        // show Loader
        showLoader(isShow) {
            if (isShow) {
                this.spinnerLoader = this.$loading.show();
            } else {
                if (this.spinnerLoader) {
                this.spinnerLoader.hide();
                }
            }
        },
    },

    async beforeMount() {
        //if user is not logged
        const isUserLogged = await EventService.checkToken();
        if (!isUserLogged) {
            this.isLoading = false;
            this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
            this.$store.dispatch("clearToken").then(() => {});
            this.$router.push({ name: "view-login" });
            return false;
        }

        //get image data
        this.getImageDataFromAPI();
    },
};
</script>
<style>
.size-info{
    position: fixed;
    top: 0%;
    right: 30%;
    width: 40%!important;
    height: 50px;
    background-color: #149EFD;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.size-info p{
    margin: 0;
    color: #fff;
}
.konva-container {
  width: 100%;
  max-width: 100%;
  height: 300px;
  min-height: 300px;
  overflow: hidden;
}
</style>