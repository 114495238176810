<template>
  <transition name="backdrop-modal">
    <div v-if="openDialog" class="backdrop-dialog"></div>
  </transition>
  <transition name="modal">
    <div
      class="dialog background-gray"
      :style="`width: ${dialogWidth}`"
      v-if="openDialog"
    >
      <div class="container-fluid">
        <!-- Header -->
        <div class="row">
          <div class="align-items-center d-flex flex-row justify-content-between m-0 row">
            <div class="container p-0 header-text">
              <div
                class="align-items-center d-flex header-text justify-content-start p-0 w-100"
              >
                <h1>{{ dialogTitle }}</h1>
              </div>
            </div>
          </div>
        </div>
        <hr class="sepGray" />
        <!-- Dialog Content -->
        <div class="container-fluid p-0 m-0">
          <!-- Generate Embed -->
          <div class="row mt-3">
            <div class="accordion accordion-console" id="accordion-question">
              <!-- Accordion Basic Options -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="panel-heading-basic-options">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panel-collapse-basic-options"
                    aria-expanded="true"
                    aria-controls="panel-collapse-basic-options"
                  >
                    BASIC OPTIONS
                  </button>
                </h2>
                <div
                  id="panel-collapse-basic-options"
                  class="accordion-collapse collapse show"
                  aria-labelledby="panel-heading-basic-options"
                >
                  <div class="accordion-body">
                    <!-- Basic Options Content -->
                    <div class="row my-2">
                      <!-- Hosts -->
                      <div class="row p-0 m-0 mb-2">
                        <div class="align-items-center d-flex flex-row col-lg-3">
                          <div class="form-label">HOSTS</div>
                        </div>
                        <div class="align-items-center d-flex flex-row col-lg-9">
                          <div class="inner-addon right-addon w-100">
                            <i class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"></i>
                            <select
                              id="field_hosts"
                              v-model="model_hosts"
                              class="form-select form-control"
                              @change="handleHosts($event)"
                            >
                              <option disabled>Hosts...</option>
                              <option
                                v-for="(host, index) in getHostsUrl"
                                :key="index"
                                :value="index"
                                :selected="index == 0"
                              >
                                {{ host }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- Player Dimensions -->
                      <div class="row p-0 m-0 mb-2">
                        <div class="align-items-center d-flex flex-row col-lg-3">
                          <div class="form-label">PLAYER DIMENSIONS</div>
                        </div>
                        <div class="align-items-center d-flex flex-row col-lg-9">
                          <div class="inner-addon right-addon w-100">
                            <i class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"></i>
                            <select
                              id="field_dimensions"
                              v-model="model_dimensions"
                              class="form-select form-control"
                              @change="handleDimensions($event)"
                            >
                              <option disabled>Player Dimensions...</option>
                              <option value="0" selected>Responsive</option>
                              <option value="1920x1080">1920x1080</option>
                              <option value="1280x720">1280x720</option>
                              <option value="852x480">852x480</option>
                              <option value="640x360">640x360</option>
                              <option value="428x240">428x240</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- Movie Versions -->
                      <div class="row p-0 m-0 mb-2">
                        <div class="align-items-center d-flex flex-row col-lg-3">
                          <div class="form-label">MOVIE VERSIONS</div>
                        </div>
                        <div class="align-items-center d-flex flex-row col-lg-9">
                          <div class="inner-addon right-addon w-100">
                            <i class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"></i>
                            <select
                              class="form-select form-control"
                              v-model="model_movieVersions"
                              @change="handleMovieVersions($event)"
                            >
                              <option disabled>Movie Versions...</option>
                              <option value selected>
                                The original version is set as default
                              </option>
                              <option
                                v-for="(movieVersion, index) in movieVersions"
                                :key="index"
                                :value="movieVersion.value"
                              >
                                {{ movieVersion.description }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- Custom Width & Height -->
                      <div v-if="isCustomDimension" class="m-0 p-0">
                        <!-- Width -->
                        <div v-show="false" class="row p-0 m-0 mb-2">
                          <div class="align-items-center d-flex flex-row col-lg-3">
                            <div class="form-label">WIDTH</div>
                          </div>
                          <div class="align-items-center col-lg-9 d-flex flex-row">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Width"
                              spellcheck="false"
                              autocomplete="off"
                              autocapitalize="none"
                              @keypress="numbersOnly($event)"
                              @click.right.prevent
                              @copy.prevent
                              @paste.prevent
                              @input="handleWidth($event)"
                              v-model="model_width"
                            />
                          </div>
                        </div>
                        <!-- Height -->
                        <div v-show="false" class="row p-0 m-0 mb-2">
                          <div class="align-items-center d-flex flex-row col-lg-3">
                            <div class="form-label">HEIGHT</div>
                          </div>
                          <div class="align-items-center col-lg-9 d-flex flex-row">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Height"
                              spellcheck="false"
                              autocomplete="off"
                              autocapitalize="none"
                              @keypress="numbersOnly($event)"
                              @click.right.prevent
                              @copy.prevent
                              @paste.prevent
                              @input="handleHeight($event)"
                              v-model="model_height"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Accordion Advanced Options -->
              <div class="accordion-item">
                <h2 class="accordion-header" id="panel-heading-advanced-options">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panel-collapse-advanced-options"
                    aria-expanded="true"
                    aria-controls="panel-collapse-advanced-options"
                  >
                    ADVANCED OPTIONS
                  </button>
                </h2>
                <div
                  id="panel-collapse-advanced-options"
                  class="accordion-collapse collapse show"
                  aria-labelledby="panel-heading-advanced-options"
                >
                  <div class="accordion-body">
                    <!-- Advanced Options Content -->
                    <div class="row mt-2">
                      <!-- Check Advanced Options -->
                      <div class="row p-0 m-0 mb-4">
                        <div class="align-items-center d-flex flex-row">
                          <div class="form-check form-switch">
                            <input
                              data-bs-toggle="collapse"
                              data-bs-target="#advanced_options"
                              aria-expanded="false"
                              aria-controls="advanced_options"
                              class="form-check-input"
                              type="checkbox"
                              id="check-enable-advanced-options"
                              v-model="model_check_enable_advance_options"
                              @input="handleCheckEnableOptions($event)"
                            />
                            <label
                              class="content-form"
                              for="check-enable-advanced-options"
                              >Enable Advanced Options</label
                            >
                          </div>
                        </div>
                      </div>
                      <!-- Advanced Options Container -->
                      <div id="advanced_options" class="m-0 p-0 mt-2 collapse">
                        <!-- Audio Language -->
                        <div v-show="false" class="row p-0 m-0 mb-2">
                          <div class="align-items-center d-flex flex-row col-lg-3">
                            <div class="form-label">AUDIO LANGUAGE</div>
                          </div>
                          <div class="align-items-center d-flex flex-row col-lg-9">
                            <div class="inner-addon right-addon w-100">
                              <i class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"></i>
                              <select
                                v-model="model_audio_language"
                                @change="handleAudioLanguage($event)"
                                class="form-select form-control"
                              >
                                <option disabled>Audio language...</option>
                                <option value selected>Default language</option>
                                <option
                                  v-for="(language, index) in arrAudioLang"
                                  :key="index"
                                  :value="language.isoCode"
                                >
                                  {{ language.isoCode }} - {{ language.language }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <!-- Subtitles Language -->
                        <div class="row p-0 m-0 mb-2">
                          <div class="align-items-center d-flex flex-row col-lg-3">
                            <div class="form-label">SUBTITLES LANGUAGE</div>
                          </div>
                          <div class="align-items-center d-flex flex-row col-lg-9">
                            <div class="inner-addon right-addon w-100">
                              <i class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"></i>
                              <select
                                v-model="model_subtitles_language"
                                @change="handleDefaultSubtitlesLanguage($event)"
                                class="form-select form-control"
                              >
                                <option disabled selected>Subtitles language...</option>
                                <option value selected>No subtitle set by default</option>
                                <option
                                  v-for="(language, index) in arrSubtitlesLang"
                                  :key="index"
                                  :value="language.isoCode"
                                >
                                  {{ language.isoCode }} - {{ language.language }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <!-- Checks -->
                        <div class="row p-0 m-0 mt-3">
                          <div
                            class="align-items-center d-flex flex-row flex-wrap justify-content-start"
                          >
                            <!-- Autoplay -->
                            <div class="form-check form-switch me-5">
                              <input
                                id="check-autoplay"
                                class="form-check-input"
                                type="checkbox"
                                @input="handleAutoplay($event)"
                                v-model="model_autoplay"
                              />
                              <label class="content-form" for="check-autoplay"
                                >Autoplay</label
                              >
                            </div>
                            <!-- Loop -->
                            <div class="form-check form-switch me-5">
                              <input
                                id="check-loop"
                                class="form-check-input"
                                type="checkbox"
                                @input="handleLoop($event)"
                                v-model="model_loop"
                              />
                              <label class="content-form" for="check-loop">Loop</label>
                            </div>
                            <!-- Controls -->
                            <div class="form-check form-switch me-5">
                              <input
                                id="check-controls"
                                class="form-check-input"
                                type="checkbox"
                                @input="handleControls($event)"
                                v-model="model_controls"
                              />
                              <label class="content-form" for="check-controls"
                                >Controls</label
                              >
                            </div>
                            <!-- Full-Screen Toggle -->
                            <div class="form-check form-switch me-5">
                              <input
                                id="check-fullscreen"
                                class="form-check-input"
                                type="checkbox"
                                @input="handleFullScreen($event)"
                                v-model="model_fullscreen"
                              />
                              <label class="content-form" for="check-fullscreen"
                                >Full-Screen Toggle</label
                              >
                            </div>
                            <!-- Picture-In-Picture Toggle -->
                            <div class="form-check form-switch me-5">
                              <input
                                id="check-pip"
                                class="form-check-input"
                                type="checkbox"
                                @input="handlePip($event)"
                                v-model="model_pip"
                              />
                              <label class="content-form" for="check-pip"
                                >Picture-In-Picture Toggle</label
                              >
                            </div>
                          </div>
                        </div>
                        <!-- Group Volume -->
                        <div class="row m-0 mt-2">
                          <div
                            class="align-items-center d-flex flex-row justify-content-center m-0 py-2"
                          >
                            <!-- Check Volume -->
                            <div class="form-check form-switch me-5">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="check-volume"
                                v-model="model_check_volume"
                                :disabled="isDisabledVolume"
                                @input="handleCheckVolume($event)"
                              />
                              <label class="content-form" for="check-volume"
                                >Volume</label
                              >
                            </div>
                            <!-- Slider Volume -->
                            <div class="container-fluid d-flex w-100 p-0">
                              <div
                                class="align-items-center container-fluid d-flex flex-row justify-content-end p-0 w-100"
                              >
                                <i class="opacity60 zmdi zmdi-volume-down"></i>
                                <input
                                  type="range"
                                  class="ms-1 me-1 p-0 w-100"
                                  min="0"
                                  max="1"
                                  step="0.1"
                                  :disabled="isDisabledVolume"
                                  v-model="model_slider_volume"
                                  @input="handleVolumeSlider($event)"
                                />
                                <i class="opacity60 zmdi zmdi-volume-up"></i>
                              </div>
                              <div
                                class="align-items-center d-flex flex-row justify-content-center ms-3 small box-code"
                                style="width: 40px; height: 25px"
                              >
                                {{ getVolumeToView() }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--<div class="position-absolute bg-black" style="width:100%;height:100px"></div>-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row p-0 m-0">
                <div class="container d-flex flex-column justify-content-center">
                  <div class="form-label mb-2">GENERATED CODE</div>
                  <div
                    class="align-items-center d-flex flex-row justify-content-between row"
                  >
                    <div class="col-sm-11">
                      <div
                        class="content-form word-break-all small box-code"
                        style="min-height: 60px"
                      >
                        {{ generatedCode }}
                      </div>
                    </div>
                    <div class="col-sm-1">
                      <button
                        @click="copyTextToClipboard(generatedCode, 'Code')"
                        class="icon-button float-end"
                        alt="COPY"
                        title="COPY"
                      >
                        <i class="zmdi zmdi-copy zmdi-hc-lg"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Buttons Close & Generate code -->
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="sendCloseDialog()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>Close </span>
            </button>
            <!-- <button
              @click="generateEmbedCode()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span>
                <i class="zmdi zmdi-check"></i>Generate Code
              </span>
            </button>-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods.js";
import languageISOCode from "@/data/iso_language_code.json";

export default {
  props: {
    openDialog: Boolean,
    dialogTitle: String,
    dialogWidth: String,
    audioLang: Array,
    subtitlesLang: Array,
    videoGUI: String,
    movieVersions: Array,
  },
  emits: ["closeDialog"],
  components: {},
  data() {
    return {
      languageISOCode,
      arrAudioLang: [],
      arrSubtitlesLang: [],
      isCustomDimension: false,
      isEnableAdvancedOptions: false,
      isDisabledVolume: false,
      generatedCode: "",
      model_hosts: 0,
      model_dimensions: 0,
      model_width: 0,
      model_height: 0,
      model_check_enable_advance_options: false,
      model_audio_language: "",
      model_subtitles_language: "",
      model_autoplay: true,
      model_loop: false,
      model_controls: true,
      model_fullscreen: true,
      model_pip: true,
      model_check_volume: false,
      model_slider_volume: 0,
      model_movieVersions: "",
      hostsUrl: [],
    };
  },
  computed: {
    getHostsUrl() {
      return this.checkHostsUrl();
    },
  },
  watch: {},
  methods: {
    sendCloseDialog() {
      this.$emit("closeDialog");
    },
    generateEmbedCode() {
      let frameId = this.generateIframeId();
      let divId = this.generateDivId();
      let guid = this.videoGUI;

      let divStyleResponsive = `style='position:relative; padding-bottom:56.25%; height:0; overflow:hidden;'`;
      let divStyleNotResponsive = `style='position:relative; overflow:hidden; display:inherit; width:${this.model_width}px; height:${this.model_height}px;'`;

      let iframeStyleResponsive = `style='position:absolute; display:inherit; top:0; left:0; width:100%; height:100%;'`;
      let iframeStyleNotResponsive = `style='position:relative; display:inherit; width:100%; height:100%;'`;

      let advancedOptionsCode = "";

      if (this.isEnableAdvancedOptions) {
        advancedOptionsCode = `${this.getAutoplayString()}${this.getVolumeString()}${this.getDefaultSubtitlesLanguage()}${this.getLoopString()}${this.getControlsString()}${this.getFullScreensString()}${this.getPipString()}`;
      }

      // default generated code
      let defaultGeneratedCode = `<div id='${divId}' ${
        !this.isCustomDimension ? divStyleResponsive : divStyleNotResponsive
      }><iframe id='${frameId}' ${
        !this.isCustomDimension ? iframeStyleResponsive : iframeStyleNotResponsive
      } src='${this.getHost()}/${guid}?${this.getMovieVersion()}${advancedOptionsCode}' frameborder='0' mozallowfullscreen='true' webkitallowfullscreen='true' allowfullscreen='true' allow='autoplay; fullscreen'></iframe></div>`;

      this.generatedCode = defaultGeneratedCode;
    },
    checkHostsUrl() {
      this.hostsUrl = [];
      if (process.env.VUE_APP_EMBED_CODE_HOST1) {
        this.hostsUrl.push(process.env.VUE_APP_EMBED_CODE_HOST1);
      }
      if (process.env.VUE_APP_EMBED_CODE_HOST2) {
        this.hostsUrl.push(process.env.VUE_APP_EMBED_CODE_HOST2);
      }
      if (process.env.VUE_APP_EMBED_CODE_HOST3) {
        this.hostsUrl.push(process.env.VUE_APP_EMBED_CODE_HOST3);
      }
      if (process.env.VUE_APP_EMBED_CODE_HOST4) {
        this.hostsUrl.push(process.env.VUE_APP_EMBED_CODE_HOST4);
      }
      return this.hostsUrl;
    },
    numbersOnly(event) {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    handleHosts(event) {
      console.log("++ handleHosts:", event.target.value);
      this.generateEmbedCode();
    },
    handleDimensions(event) {
      if (event.target.value != "0") {
        //custom dimension
        this.initCustomDimensions(event.target.value);
        this.isCustomDimension = true;
      } else {
        this.isCustomDimension = false;
      }
      this.generateEmbedCode();
    },
    handleMovieVersions(event) {
      console.log("++ handleMovieVersions:", event.target.value);
      this.generateEmbedCode();
    },
    handleWidth(event) {
      console.log("++ handleWidth:", event.target.value);
      this.generateEmbedCode();
    },
    handleHeight(event) {
      console.log("++ handleHeight:", event.target.value);
      this.generateEmbedCode();
    },
    handleCheckEnableOptions(event) {
      if (event.target.checked) {
        //advance options
        this.initAdvanceOptions();
        this.isEnableAdvancedOptions = true;
      } else {
        this.isEnableAdvancedOptions = false;
      }
      this.generateEmbedCode();
    },
    handleAudioLanguage(event) {
      console.log("++ handleAudioLanguage:", event.target.value);
      this.generateEmbedCode();
    },
    handleDefaultSubtitlesLanguage(event) {
      console.log("++ handleDefaultSubtitlesLanguage:", event.target.value);
      this.generateEmbedCode();
    },
    handleAutoplay(event) {
      console.log("++ handleAutoplay:", event.target.checked);
      if (event.target.checked) {
        this.model_autoplay = true;
        this.model_slider_volume = 0;
        this.model_check_volume = false;
        this.isDisabledVolume = true;
      } else {
        this.model_autoplay = false;
        this.model_slider_volume = 1;
        this.model_check_volume = true;
        this.isDisabledVolume = false;
      }
      this.generateEmbedCode();
    },
    handleLoop(event) {
      console.log("++ handleLoop:", event.target.checked);
      if (event.target.checked) {
        this.model_loop = true;
      } else {
        this.model_loop = false;
      }
      this.generateEmbedCode();
    },
    handleControls(event) {
      console.log("++ handleControls:", event.target.checked);
      if (event.target.checked) {
        this.model_controls = true;
      } else {
        this.model_controls = false;
      }
      this.generateEmbedCode();
    },
    handleFullScreen(event) {
      console.log("++ handleFullScreen:", event.target.checked);
      if (event.target.checked) {
        this.model_fullscreen = true;
      } else {
        this.model_fullscreen = false;
      }
      this.generateEmbedCode();
    },
    handlePip(event) {
      console.log("++ handlePip:", event.target.checked);
      if (event.target.checked) {
        this.model_pip = true;
      } else {
        this.model_pip = false;
      }
      this.generateEmbedCode();
    },
    handleCheckVolume(event) {
      console.log("++ handleCheckVolume:", event.target.checked);
      if (event.target.checked) {
        this.isDisabledVolume = false;
      } else {
        this.model_slider_volume = 0;
      }
      this.generateEmbedCode();
    },
    handleVolumeSlider(event) {
      console.log("++ handleVolumeSlider:", event.target.value);
      if (this.model_slider_volume > 0) {
        this.model_check_volume = true;
      }
      if (this.model_slider_volume == 0) {
        this.model_check_volume = false;
      }
      this.generateEmbedCode();
    },
    generateIframeId() {
      return "iframe_" + CommonMethods.randomIntFromInterval(1000000, 9999999).toString();
    },
    generateDivId() {
      return "div_" + CommonMethods.randomIntFromInterval(1000000, 9999999).toString();
    },
    getAudioLangIsoCode() {
      this.arrAudioLang = this.pushLanguage(this.audioLang);
    },
    getSubtitlesLangIsoCode() {
      this.arrSubtitlesLang = this.pushLanguage(this.subtitlesLang);
    },
    pushLanguage(propArr) {
      let arr;
      let arrToPush = [];
      if (propArr) {
        if (propArr.length > 0) {
          for (let i = 0; i < propArr.length; i++) {
            arr = this.languageISOCode.find((el) => el.isoCode == propArr[i]);
            if (arr) {
              arrToPush.push({
                isoCode: arr.isoCode,
                language: arr.language,
              });
            }
          }
        }
      }

      return arrToPush;
    },
    getVolumeToView() {
      return this.model_slider_volume * 100;
    },
    getHost() {
      return this.hostsUrl[this.model_hosts];
    },
    getAudioLanguageString() {
      let str = "";
      if (this.model_audio_language == "") {
        str = "";
      } else {
        str = "&ld=" + this.model_audio_language;
      }
      return str;
    },
    getVolumeString() {
      let str = "";
      str = "&vol=" + this.model_slider_volume;
      return str;
    },
    getDefaultSubtitlesLanguage() {
      let str = "";
      if (this.model_subtitles_language == "") {
        str = "";
      } else {
        str = "&dst=" + this.model_subtitles_language;
      }
      return str;
    },
    getAutoplayString() {
      let str = "";
      if (this.model_autoplay) {
        str = "&ap=1";
      } else {
        str = "";
      }
      return str;
    },
    getLoopString() {
      let str = "";
      if (this.model_loop) {
        str = "&lp=1";
      } else {
        str = "";
      }
      return str;
    },
    getControlsString() {
      let str = "";
      if (!this.model_controls) {
        str = "&cts=0";
      } else {
        str = "";
      }
      return str;
    },
    getFullScreensString() {
      let str = "";
      if (!this.model_fullscreen) {
        str = "&fs=0";
      } else {
        str = "";
      }
      return str;
    },
    getPipString() {
      let str = "";
      if (!this.model_pip) {
        str = "&pip=0";
      } else {
        str = "";
      }
      return str;
    },
    getMovieVersion() {
      let str = "";
      if (this.model_movieVersions) {
        str = "&vfn=" + this.model_movieVersions;
      } else {
        str = "";
      }
      return str;
    },
    init() {
      this.arrAudioLang = [];
      this.arrSubtitlesLang = [];
      this.isCustomDimension = false;
      this.isEnableAdvancedOptions = false;
      this.isDisabledVolume = true;
      this.model_hosts = 0;
      this.model_dimensions = 0;
      this.model_movieVersions = "";
      this.model_width = 0;
      this.model_height = 0;
      this.model_check_enable_advance_options = false;
    },
    initDefaultCode() {
      this.generatedCode = "";
    },
    initCustomDimensions(value) {
      if (value != "1") {
        //if != 1 (custom dimension, split values)
        let values = value.split("x");
        this.model_width = Number(values[0]);
        this.model_height = Number(values[1]);
      } else {
        //if == 1 (custom dimensions, init to 0)
        this.model_width = 0;
        this.model_height = 0;
      }
    },
    initAdvanceOptions() {
      this.isDisabledVolume = true;
      this.model_audio_language = "";
      this.model_subtitles_language = "";
      this.model_autoplay = true;
      this.model_loop = false;
      this.model_controls = true;
      this.model_fullscreen = true;
      this.model_pip = true;
      this.model_check_volume = false;
      this.model_slider_volume = 0;
    },
    copyTextToClipboard(textToCopy, what) {
      if (navigator.clipboard) {
        if (textToCopy == "" || textToCopy == null) {
          this.$root.addSnackBarMsg("There is nothing to copy", "warning");
          return;
        }
        navigator.clipboard.writeText(textToCopy).then(() => {
          this.$root.addSnackBarMsg(what + " copied to the clipboard", "info");
        });
      } else {
        this.$root.addSnackBarMsg(
          "This feature is available only in secure contexts (HTTPS)",
          "warning"
        );
        console.warn("This feature is available only in secure contexts (HTTPS)");
      }
    },
  },
  async updated() {
    if (this.openDialog) {
      //if user is not logged
      const isUserLogged = await EventService.checkToken();
      if (!isUserLogged) {
        this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
    }
    this.init();
    this.initDefaultCode();
    this.getAudioLangIsoCode();
    this.getSubtitlesLangIsoCode();
    this.generateEmbedCode();
  },
};
</script>
