<template>
  <div class="sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- All -->
      <ul class="unstyled nav-parent-item">
        <li>
          <router-link :to="{ name: 'view-users-new' }" exact>Add new user</router-link>
        </li>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <li>
          <router-link :to="{ name: 'view-users', params: { id: 'users' } }" exact
            >Users</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'view-users', params: { id: 'admins' } }" exact
            >Admins</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    isActive() {
      return this.$route.name == "view-users-all";
    },
  },
  methods: {},
  created() {},
};
</script>
