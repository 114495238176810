<template>
  <div class="sidebar">
    <nav class="main-left-nav" role="navigation">
      <ul class="unstyled nav-parent-item">
        <li>
          <router-link :to="{ name: 'view-settings-thumbnail' }" exact>Thumbnails Generator</router-link>
        </li>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <li>
          <router-link :to="{ name: 'view-settings-protection' }" exact>Protection Rules</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>