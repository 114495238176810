<template>
  <button ref="baseIconBtn" :type="typeButton" class="btn button-outline-1" :class="alignClass">
    <slot></slot>
  </button>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    alignClass: String,
    typeButton: String,
  },
};
</script>
