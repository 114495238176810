<template>
  <div class="sidebar">
    <nav class="main-left-nav" role="navigation">
      <ul class="unstyled nav-parent-item">
        <!-- Encoding Jobs -->
        <li>
          <router-link :to="{ name: 'view-monitoring-encoding-jobs'}" exact>Encoding Jobs</router-link>
        </li>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <!-- Encoding Archive -->
        <li>
          <router-link :to="{ name: 'view-monitoring-encoding-archive'}" exact>Encoding Archive</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>