<template>
  <teleport to="body">
    <vue3-snackbar bottom :duration="3000"></vue3-snackbar>
  </teleport>
  <div class="theme--dark application">
    <div class="application-wrap">
      <!-- Header -->
      <header-comp />
      <!-- Content -->
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="$route.path"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import HeaderComp from "@/components/header/HeaderComp.vue";
import HeaderNoLogin from "@/components/header/HeaderNoLogin.vue";

export default {
  name: "App",
  components: {
    HeaderComp,
    HeaderNoLogin,
  },
  data() {
    return {};
  },
  methods: {
    addSnackBarMsg(text, type, bkg) {
      this.$snackbar.add({
        type: type,
        text: text,
        background: bkg,
        duration: 5000,
      });
    },
  },
  async beforeMount() {},
  async mounted() {},
};
</script>

<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
