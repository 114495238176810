<template>
  <transition name="backdrop-modal">
    <div v-if="openDialog" class="backdrop-dialog"></div>
  </transition>
  <transition name="modal">
    <div
      class="dialog background-gray"
      :style="`width: ${dialogWidth}`"
      v-if="openDialog"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <!-- Dialog Content -->
        <div class="row">
          <!-- Video -->
          <div class="col-md-6 mb-3">
            <div class="form-label mb-1">VIDEO</div>
            <div class="border-mainblue bg-black">
              <!-- Video Player Plyr -->
              <vue-plyr ref="plyrPlayer_AudioTrack" :options="options" class="m-0 p-0">
                <video
                  class="m-0 p-0 w-100"
                  @timeupdate="updateTime"
                  ref="video_preview_cc"
                  controls
                  crossorigin
                  playsinline
                  :data-poster="dataElement.video_poster.uri"
                >
                  <!-- Video track -->
                  <source :src="dataElement.video_src" type="video/mp4" />
                  <!-- VTT track -->
                  <track
                    default
                    id="chaptersTrack01"
                    class="chaptersTrack"
                    kind="captions"
                    ref="chapters"
                    @load="getChaptersReady"
                    :src="dataElement.subtitle.uri"
                    :srclang="dataElement.subtitle.lang"
                    :label="getLanguageLabel(dataElement.subtitle.lang)"
                  />
                </video>
              </vue-plyr>
            </div>
          </div>
          <!-- Audio Track -->
          <div class="col-md-6 mb-3">
            <div class="form-label mb-1">
              AUDIO TRACK -
              {{ getLanguageLabel(dataElement.subtitle.lang).toUpperCase() }}
            </div>
            <div id="track-scroll-container" class="row vtt-track-container">
              <div
                class="vtt-text-area my-2"
                v-for="(chapter, index) in chapters"
                :key="index"
              >
                <div :id="'index_' + index" class="form-label my-1 timecode">
                  {{ formatTime(chapter.startTime) }} -->
                  {{ formatTime(chapter.endTime) }}
                </div>
                <textarea
                  @click="skipTo(chapter)"
                  v-model="chapter.text"
                  @input="isChangesSaved = false"
                  class="form-control"
                  :class="{ activeChapter: isActive(chapter, index) }"
                  spellcheck="false"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <!-- Export area -->
            <textarea
              id="export-vtt"
              v-model="export_vtt"
              class="form-control"
              spellcheck="false"
              rows="20"
              hidden
            ></textarea>
          </div>
        </div>

        <!-- Buttons Cancel & Ok -->
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="sendCloseDialog()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>Cancel </span>
            </button>
            <button
              @click="sendOk()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span> <i class="zmdi zmdi-check"></i>Ok </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import languageISOCode from "@/data/iso_language_code.json";

export default {
  props: {
    openDialog: Boolean,
    dialogTitle: String,
    dataElement: Object,
    dialogWidth: String,
  },
  emits: ["closeDialog", "confirmData"],
  components: {},
  data() {
    return {
      chapters: [],
      currentTime: 0,
      options: {},
      languageISOCode,
      export_vtt: "",
      isChangesSaved: true,
    };
  },
  methods: {
    sendCloseDialog() {
      this.$emit("closeDialog");
    },
    sendOk() {
      if (this.isChangesSaved) {
        this.sendCloseDialog();
        return;
      }
      let cphts = this.chapters;
      let vtt = "";
      let startVttTime, endVttTime;
      vtt = "WEBVTT" + "\n\n";

      for (let j = 0; j < cphts.length; j++) {
        startVttTime = this.formatTime(cphts[j].startTime);
        endVttTime = this.formatTime(cphts[j].endTime);
        console.log("startVttTime:", startVttTime, cphts[j].startTime);
        console.log("endVttTime:", endVttTime, cphts[j].endTime);

        vtt = vtt + startVttTime + " --> " + endVttTime + "\n" + cphts[j].text + "\n\n";
      }
      this.export_vtt = vtt.trim();

      const objReturn = {
        subtitle: {
          lang: this.dataElement.subtitle.lang,
          uri: this.dataElement.subtitle.uri,
        },
        index: this.dataElement.index,
        vttText: this.export_vtt,
      };

      this.$emit("confirmData", objReturn);
    },
    getChaptersReady() {
      let chapters = this.$refs.chapters;
      this.chapters = chapters.track.cues;
      console.log("this.chapters", this.chapters);
    },
    updateTime() {
      if (this.openDialog) {
        this.currentTime = this.$refs.video_preview_cc.currentTime;
      }
    },
    skipTo(chapter) {
      console.log("** chapter", chapter);
      if (this.openDialog) {
        this.$refs.video_preview_cc.currentTime = chapter.startTime + 0.01;
      }
    },
    isActive(chapter, index) {
      const isTrue =
        chapter.endTime > this.currentTime && chapter.startTime <= this.currentTime;
      if (isTrue) {
        const el = document.getElementById("index_" + index);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
      return isTrue;
    },
    isCompleted(chapter) {
      return chapter.endTime <= this.currentTime;
    },
    formatTime(timeInsecMsec) {
      console.log("+++++++++++++++++++++++++++++++++++++++++++++++");
      console.log("++ formatTime get timeInsecMsec", timeInsecMsec);
      const msec = (timeInsecMsec % 1) * 1000;
      console.log("++ msec", msec);
      // const secMsec = Math.floor(timeInsecMsec % 10000) * 1000; //giusto fino a 59 minuti
      const secMsec = Math.floor(timeInsecMsec % 100000) * 1000; //per timecode non superiori alle 24H (23:59:59.000)
      console.log("++ secMsec", secMsec);
      let duration = msec + secMsec;
      let ms = duration % 1000;
      duration = (duration - ms) / 1000;
      let secs = duration % 60;
      duration = (duration - secs) / 60;
      let mins = duration % 60;
      let hrs = (duration - mins) / 60;
      ms =
        Math.round(ms) < 100
          ? Math.round(ms) == 0
            ? "000"
            : "0" + Math.round(ms)
          : Math.round(ms);
      hrs = hrs < 10 ? "0" + hrs : hrs;
      mins = mins < 10 ? "0" + mins : mins;
      secs = secs < 10 ? "0" + secs : secs;
      console.log(
        "++ formatTime put timeInsecMsec converted",
        hrs + ":" + mins + ":" + secs + "." + ms
      );
      return hrs + ":" + mins + ":" + secs + "." + ms;
    },
    getLanguageLabel(isoCode) {
      if (isoCode == "" || isoCode == undefined || isoCode == null) return "all";
      let element = this.languageISOCode.find((el) => el.isoCode === isoCode);
      return element.language;
    },
  },
  updated() {
    if (this.openDialog) {
      this.isChangesSaved = true;
      this.currentTime = 0;
      this.export_vtt = "";
      this.options = {
        quality: { default: "720p" },
        captions: { active: true, language: this.dataElement.subtitle.lang },
      };
      console.log(this.options);

      // this.$refs.plyrPlayer_AudioTrack.player.captions = { active: false };
      // if (this.$refs.plyrPlayer_AudioTrack) {
      //   console.log(
      //     "this.$refs.plyrPlayer_AudioTrack",
      //     this.$refs.plyrPlayer_AudioTrack
      //   );
      // }
    }
  },
  beforeUnmount() {
    if (this.$refs.plyrPlayer_AudioTrack) {
      this.$refs.plyrPlayer_AudioTrack.player.destroy();
    }
  },
};
</script>
<style>
.timecode {
  color: #23d1a2;
}
</style>
