<template>
  <div class="d-flex justify-content-end">
    <div class="align-items-center d-flex justify-content-end">
      <div class="header-search-2">
        <a
          v-if="!searching"
          href="#"
          @click.prevent="searchToggleBtn"
          class="search-toggle"
          id="searchToggle"
          title="SEARCH FILTER"
          name="SEARCH FILTER"
        >
          <i class="zmdi zmdi-search s-open"></i>
          <i class="zmdi zmdi-close s-close"></i>
        </a>
        <a
          v-if="searching"
          href="#"
          @click.prevent="clearSearchResult"
          class="clear-search"
          id="clearSearch"
          title="REMOVE SEARCH FILTER"
          name="REMOVE SEARCH FILTER"
        >
          <i class="zmdi zmdi-close"></i>
        </a>
        <div class="search-wrap-2" id="searchWrap">
          <form class="search-bar" novalidate @submit.prevent="submitSearch()">
            <input
              placeholder="Search..."
              type="text"
              id="inputSearch"
              name="inputSearch"
              ref="inputSearch"
              v-model.trim="searchText"
              spellcheck="false"
              autocomplete="off"
              autocapitalize="none"
              @keydown="checkKeyDownAlphaNumSpecial($event)"
            />
            <button type="submit" class="button-search">
              <i class="zmdi zmdi-search"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  emits: ["txtToSearch"],
  data() {
    return {
      searchText: "",
      minCharSearch: 3,
      searching: false,
    };
  },
  methods: {
    ...mapMutations(["setSearchImage", "setSearchVideo"]),
    checkKeyDownAlphaNumSpecial(event) {
      console.log("++ checkKeyDownAlphaNumSpecial, no filter", event);
      // if (!/[ A-Za-z0-9_@./#&+-,;:{}?!="'%$òàùèìé]/.test(event.key)) {
      //   this.ignoredValue = event.key ? event.key : "";
      //   event.preventDefault();
      // }
    },
    searchToggleBtn() {
      const searchToggle = document.getElementById("searchToggle");
      const searchWrap = document.getElementById("searchWrap");
      if (searchToggle.classList.contains("search-open")) {
        searchToggle.classList.remove("search-open");
        searchWrap.classList.remove("search-open");
      } else {
        document.getElementById("inputSearch").value = "";
        this.searchText = "";
        searchToggle.classList.add("search-open");
        searchWrap.classList.add("search-open");
        setTimeout(() => {
          this.$refs.inputSearch.focus();
        }, 100);
      }
    },
    clearSearchResult() {
      this.searching = false;
      if (this.$route.name == "view-pictures") {
        this.setSearchImage(null);
      }
      if (this.$route.name == "view-videos") {
        this.setSearchVideo(null);
      }
      this.$emit("clearTxtToSearch");
    },
    submitSearch() {
      if (this.searchText.length < this.minCharSearch) {
        this.$root.addSnackBarMsg("Enter at least 3 characters", "info");
        return;
      }
      this.searching = true;
      this.searchToggleBtn();
      console.warn("** submitSearch searchText", this.searchText);
      this.$emit("txtToSearch", this.searchText);
    },
  },
  computed: {
    ...mapState(["searchImage", "searchVideo"]),
  },
  mounted() {
    if (this.searchImage && this.$route.name == "view-pictures") {
      this.searching = true;
      this.$emit("txtToSearch", this.searchImage);
    }
    if (this.searchVideo && this.$route.name == "view-videos") {
      this.searching = true;
      this.$emit("txtToSearch", this.searchVideo);
    }
  },
};
</script>
