<template>
  <div class="container content-container">
    <div class="row p-0 m-0">
      <div class="content-center header-text">
        <h1>Page not found</h1>
      </div>
      <div class="align-items-center container-fluid d-inline-flex justify-content-center my-5">
        <router-link
          class="d-inline-flex btn button-outline-1 small"
          style="white-space: nowrap;"
          :to="{ name: 'view-videos-all' }"
        >
          <span>
            <i class="zmdi zmdi-home"></i>Home
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
