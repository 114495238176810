<template>
  <div class="sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- Attributes -->
      <ul class="unstyled nav-parent-item">
        <li>
          <router-link :to="{ name: 'view-categories' }">Categories</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'view-tags' }">Tags</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'view-areas' }">Areas</router-link>
        </li>
      </ul>
      <hr role="separator" aria-orientation="horizontal" class="divider" />
      <!-- Sort -->
      <ul class="unstyled nav-parent-item">
        <li
          v-for="item in sortOrder"
          :key="item.text"
          @click.prevent="setOrder(item.value)"
        >
          <a
            href
            :class="{
              active: order == item.value,
            }"
            >{{ item.text }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      order: 0,
    };
  },
  computed: {
    sortOrder() {
      return [
        {
          text: "Alphabetically (A-Z)",
          value: "0",
        },
        {
          text: "Alphabetically (Z-A)",
          value: "1",
        },
      ];
    },
  },
  methods: {
    setOrder(order) {
      this.order = order;
      console.log("++ setOrder order:", order);
      this.$store.dispatch("setAttributesOrder", order);
      // if (this.$route.name != "library-all") {
      //   this.$router.push({ name: "library-all" });
      // }
    },
  },
  created() {
    this.$store.dispatch("setAttributesOrder", this.order);
  },
};
</script>
