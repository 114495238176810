<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              VIDEOS
              <span class="text-white small">
                <span class="mx-3"> <i class="fa-solid fa-file-video"></i> </span>New
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Drag file zone -->
        <div v-if="allFiles.length == 0" class="container d-flex justify-content-center">
          <form
            id="drop-form-instant"
            class="align-items-center d-flex flex-row justify-content-center w-100"
            @drop="handleFileDrop($event)"
            @dragenter="onDragEnter($event)"
            @dragover="onDragEnter($event)"
            @dragleave="onDragLeave($event)"
          >
            <div class="drag-file" id="drag-zone">
              <div class="icon mb-3">
                <i class="fa-solid fa-file-video"></i>
              </div>
              <h6>Drop your video here</h6>
              <span class="mb-3">OR</span>
              <button
                @click="chooseFile()"
                type="button"
                class="btn button-outline-1 small"
              >
                <span> <i class="fa-solid fa-folder-open"></i>Browse videos </span>
              </button>
              <input
                id="fileUpload"
                type="file"
                :accept="videoAccepted"
                v-on:change="handleFileUpload($event)"
                hidden
                multiple
              />
            </div>
          </form>
        </div>
        <!-- Select Language -->
        <div v-if="allFiles.length == 0" class="row p-0 m-0 my-4">
          <div class="align-items-center d-flex flex-row col-lg-2">
            <div class="form-label">AUDIO LANGUAGE</div>
          </div>
          <div class="align-items-center d-flex flex-row col-lg-10">
            <div class="inner-addon right-addon w-100">
              <i
                class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                style="padding: 9px 16px !important"
              ></i>
              <select
                class="form-select form-control my-2"
                required
                v-model="modelLanguage"
              >
                <option disabled value>Language...</option>
                <option
                  v-for="(language, index) in languageISOCode"
                  :key="index"
                  :value="language.isoCode"
                >
                  {{ language.isoCode }} - {{ language.language }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Process List -->
        <div v-else id="process_list" class="row m-0 padding-x-4px">
          <div class="header-text p-0 w-100">
            <h1>UPLOAD VIDEO</h1>
          </div>
          <table class="table-bordered table-responsive-md table-setup">
            <thead class="table-header-font">
              <tr class="td-center">
                <th>FILE NAME</th>
                <th width="90">FILE SIZE</th>
                <th width="220">PROGRESS</th>
                <th width="110">STATUS</th>
                <th width="80">ACTIONS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <tr  v-for="fileInfo in allFiles" :key="fileInfo.file.name">
                <!-- File Name -->
                <td>{{ fileInfo.file.name }}</td>
                <!-- Size -->
                <td class="td-center">{{ formatBytes(fileInfo.file.size) }}</td>
                <!-- Progress -->
                <td class="td-center">
                  <div class="progress my-1" style="background-color: #adadad">
                    <div
                      id="progress_upload"
                      :class="`progress-bar ${fileInfo.uploadPercentage == 100 ? '':'progress-bar-striped progress-bar-animated'} bg-success`"
                      role="progressbar1"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="'width:' + fileInfo.uploadPercentage + '%'"
                    >
                      {{ fileInfo.uploadPercentage }}%
                    </div>
                  </div>
                </td>
                <!-- Status -->
                <td class="td-center">{{ fileInfo.upload_status }}</td>
                <!-- Actions -->
                <td v-if="fileInfo.uploadPercentage == 0" class="td-actions td-center">
                  <button
                    @click="deleteProcess(fileInfo.file.name)"
                    alt="DELETE"
                    title="DELETE"
                  >
                    <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
                  </button>
                  <button
                    @click="runProcess(fileInfo)"
                    alt="UPLOAD"
                    title="UPLOAD"
                  >
                    <i class="fa-solid fa-file-arrow-up"></i>
                  </button>
                  <!-- <button v-if="fileInfo.uploadPercentage != 0" @click="abortProcess(fileInfo)" alt="ABORT" title="ABORT">
                    <i class="zmdi zmdi-close zmdi-hc-lg"></i>
                  </button>-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Add New Video -->
        <div v-if="allFiles.length > 0" class="align-items-center d-flex justify-content-end">
          <div class="row mx-2">
            <div class="align-items-center d-flex flex-row">
              <div class="inner-addon right-addon w-100">
                <i
                  class="glyphicon zmdi zmdi-caret-down zmdi-hc-2x"
                  style="padding: 9px 16px !important"
                ></i>
                <select
                  class="form-select form-control h-100"
                  required
                  style="padding: 11px 16px !important"
                  v-model="modelLanguage"
                >
                  <option disabled value>Language...</option>
                  <option
                    v-for="(language, index) in languageISOCode"
                    :key="index"
                    :value="language.isoCode"
                  >
                    {{ language.isoCode }} - {{ language.language }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <form class="p-0 my-3">
          <button
            @click="chooseFile()"
            type="button"
            class="btn button-outline-1 small float-end"
          >
            <span> <i class="zmdi zmdi-plus"></i>Add new video </span>
          </button>
          <input
              id="fileUpload"
              type="file"
              :accept="videoAccepted"
              v-on:change="handleFileUpload($event)"
              hidden
              multiple
            />
        </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";
import languageISOCode from "@/data/iso_language_code.json";
import axios from "axios";
import store from "@/store";

export default {
  data() {
    return {
      languageISOCode,
      modelLanguage: "it",
      file: null,
      allFiles: [],
      files: [],
      dragAndDropCapable: false,
      twoGB: 2147483648,
      apiClientAxios: null,
      requestUploadFile: null,
      request: null,
      BASEURL: this.$appGlobalVariables.BASEURL_UPLOAD,
      isUploadReady: false,
      isDragEnter: false,
      // upload_status: [
      //   { label: "Ready" },
      //   { label: "In progress..." },
      //   { label: "Completed, start encoding" },
      //   { label: "Aborted" },
      //   { label: "Error" },
      // ],
      videoAccepted: "video/mp4, video/quicktime, video/avi, video/webm",
      videoFileTypes: ["video/mp4", "video/quicktime", "video/avi", "video/webm"],
      spinnerLoader: null,
    };
  },

  methods: {
    formatBytes(bytes) {
      return CommonMethods.formatBytes(bytes);
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
    handleFileUpload(event) {
      for(let i =0;i<event.target.files.length;i++){
        let tmpFile = event.target.files[i];
        if (!tmpFile) return;
        let tmpFileSize = tmpFile.size;
        // let tmpFileType = tmpFile.type;
        if (!this.validVideoFileType(tmpFile)) {
          // if (tmpFileType != "video/mp4") {
          this.$root.addSnackBarMsg(
            "Attention, the file must be of type " + this.videoAccepted,
            "warning"
          );
          return;
        }
        if (tmpFileSize === 0) {
          this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
          return;
        }
        if (tmpFileSize > this.twoGB) {
          this.$root.addSnackBarMsg("Attention, the file exceeds 2 GB", "warning");
          return;
        }

        //rinomina il file togliendo i caratteri speciali
        const theFileName = this.convertStringToSlug(tmpFile.name);

        let newFile = new File([tmpFile], theFileName, { type: tmpFile.type }); // event.target.files[0];
        this.allFiles.push({file:newFile,upload_status:'Ready',uploadPercentage:0})
        console.log("** all Files to upload:", this.file);
      }
      this.isUploadReady = true;
    },
    chooseFile() {
      document.getElementById("fileUpload").click();
    },
    resetVariables() {
      this.allFiles = [];
      this.files = [];
      if (this.request) this.request.cancel();
      this.request = null;
      this.requestUploadFile = null;
      this.isDragEnter = false;
      this.isUploadReady = false;
      this.bindEvents();
    },
    deleteProcess(fileName) {
        // Find the index of the file with the matching name
      const index = this.allFiles.findIndex(file => file.file.name === fileName);
      
      // If the file was found, remove it from the array
      if (index !== -1) {
        this.allFiles.splice(index, 1);
        this.$root.addSnackBarMsg(`Image "${fileName}" removed from list`, "info");
      }
      if(this.allFiles.length ==0){
        this.resetVariables();
      }
    },
    runProcess(file) {
      file.upload_status='In progress...'
      this.submitFile(file);
    },
    abortProcess(file) {
      // this.requestUploadFile = null;
      file.upload_status='Aborted'
      this.request.cancel();
      this.$root.addSnackBarMsg("Process cancelled", "info");
    },
    createOctectHeader(token,file) {
      let apiAxios = null;
      apiAxios = axios.create({
        baseURL: this.BASEURL,
        headers: {
          "x-file-name": file,
          "x-file-lang": this.modelLanguage,
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + token,
        },
      });
      return apiAxios;
    },
    async submitFile(file) {
      this.requestUploadFile = await this.uploadFile(file);
      let output = { status: "", data: {} };
      if (this.requestUploadFile) {
        switch (this.requestUploadFile.status) {
          case 200:
            output = { status: 200, data: this.requestUploadFile };
            console.log("Upload file status >> 200 OK", output);
            this.sendToEncoding(output,file);
            break;
          case 403:
            output = { status: 403, data: this.requestUploadFile };
            console.log("Upload file status >> 403 Forbidden", output);
            break;
          case 413:
            output = { status: 413, data: this.requestUploadFile };
            console.log("Upload file status >> 413 Payload too large", output);
            break;
          default:
            output = { status: "error", data: this.requestUploadFile };
            console.error("Upload file status >> Server error >>", output);
            break;
        }
      } else {
        console.log("Upload file status is " + this.requestUploadFile);
      }
    },
    async sendToEncoding(output,file) {
      console.log("++ process id to encode:", output.data.data.id);
      console.log("++ language to encode:", this.modelLanguage);
      // this.isChangesSaved[6] = false;
      // Start encoding
      this.showLoader(true);
      file.upload_status='Completed, start encoding'
      const apiResponseObj = await EventService.encodingJobID(
        output.data.data.id,
        this.modelLanguage,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);

      if (apiResponseObj.status != 200) {
        file.upload_status='Error'
        console.error("Encode >> error during encode", apiResponseObj.status);
        this.$root.addSnackBarMsg("ENCODE: Error during encoding", "error");
        return;
      }
    },
    convertStringToSlug(str) {
      str = str
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trim();

      return str;
    },
    // convertStringToSlug(str) {
    //   str = str
    //     .toString()
    //     .normalize("NFD")
    //     .replace(/[\u0300-\u036f]/g, "")
    //     .toLowerCase()
    //     .trim()
    //     .replace(/\s+/g, "-")
    //     .replace(/[^\w-]+/g, "")
    //     .replace(/--+/g, "-");
    //   return str;
    // },
    async uploadFile(file) {
      const isTokenValid = await EventService.checkToken();
      if (!isTokenValid) {
        return { status: "Invalid token" };
      }
      this.apiClientAxios = this.createOctectHeader(store.getters.getToken.token);

      return await this.apiClientAxios
        .post("/Files/Upload", file.file, {
          onUploadProgress: function (progressEvent) {
            file.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            console.log("Upload Progress: " + file.uploadPercentage + "%");
          }.bind(this),
        })
        .then(
          (response) => {
            console.log("API uploadFile >>", response);
            return response;
          },
          (error) => {
            console.error("API uploadFile >> error >>", error.response);
            return error.response;
          }
        );
    },

    logResponseErrors(err) {
      if (axios.isCancel(err)) {
        console.log("** axios request cancelled");
      }
    },
    /******************************/
    /** Drag & Drop File function */
    /******************************/
    bindEvents() {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach(
        function (evt) {
          if (document.getElementById("drop-form-instant")) {
            document.getElementById("drop-form-instant").addEventListener(
              evt,
              function (e) {
                e.preventDefault();
                e.stopPropagation();
              }.bind(this),
              false
            );
          }
        }.bind(this)
      );
    },
    handleFileDrop(event) {
      if (!this.isDragEnter) return;
      let filesDropped = [];
      console.error(event.dataTransfer.files.length);
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        filesDropped.push(event.dataTransfer.files[i]);
      }
      console.log("** files dropped:", filesDropped);
      this.submitDroppedFiles(filesDropped);
    },
    onDragEnter() {
      this.resetVariables();
      this.isDragEnter = true;
      document.getElementById("drag-zone").classList.add("drag-enter");
      console.log("onDragEnter onDragOver", this.isDragEnter);
    },
    onDragLeave() {
      this.resetVariables();
      document.getElementById("drag-zone").classList.remove("drag-enter");
      console.log("onDragEnter onDragOver", this.isDragEnter);
    },
    submitDroppedFiles(filesDropped) {
      for(let i =0;i<filesDropped.length;i++){
        let tmpFile = filesDropped[i];
        if (!tmpFile) return;
        let tmpFileSize = tmpFile.size;
        // let tmpFileType = tmpFile.type;
        console.log("++ tmpFile.type", tmpFile.type);
        if (!this.validVideoFileType(tmpFile)) {
          // if (tmpFileType != "video/mp4") {
          this.$root.addSnackBarMsg(
            "Attention, the file must be of type " + this.videoAccepted,
            "warning"
          );
          document.getElementById("drag-zone").classList.remove("drag-enter");
          return;
        }
        if (tmpFileSize === 0) {
          this.$root.addSnackBarMsg("Attention, the file is 0 Bytes", "warning");
          document.getElementById("drag-zone").classList.remove("drag-enter");
          return;
        }
        if (tmpFileSize > this.twoGB) {
          this.$root.addSnackBarMsg("Attention, the file exceeds 2 GB", "warning");
          document.getElementById("drag-zone").classList.remove("drag-enter");
          return;
        }

        //rinomina il file togliendo i caratteri speciali
        const theFileName = this.convertStringToSlug(tmpFile.name);

        let newFile = new File([tmpFile], theFileName, { type: tmpFile.type }); // event.target.files[0];
        this.allFiles.push({file:newFile,upload_status:'Ready',uploadPercentage:0})
        console.log("** filesDropped to upload :", this.allFiles[i]);
      }
      this.isUploadReady = true;
    },
    determineDragAndDropCapable() {
      let div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    validVideoFileType(file) {
      return this.videoFileTypes.includes(file.type);
    },
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      this.bindEvents();
    }
  },
};
</script>
