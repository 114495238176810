<template>
  <transition name="backdrop-modal">
    <div v-if="openDialog" class="backdrop-dialog"></div>
  </transition>
  <transition name="modal">
    <div
      class="dialog background-gray"
      :style="`width: ${dialogWidth};overflow:visible`"
      v-if="openDialog"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
            <!-- Dialog Content -->
            <div class="row">
              <!-- Categories -->
              <div class="m-0 my-4">
                <div class="align-items-start d-flex row">
                  <div class="col-lg-3 pe-0">
                    <div class="form-label mt-4">CHOOSE A CATEGORY:</div>
                  </div>
                  <!-- Field -->
                  <div class="align-items-center col-lg-9 p-0">
                    <Multiselect
                      id="categories"
                      placeholder="CHOOSE A CATEGORY..."
                      noResultsText="No result"
                      mode="tags"
                      label="namesearch"
                      valueProp	="catId"
                      class="p-2"
                      :value="tagsCategoriesValue"
                      :close-on-select="false"
                      :options="dataElement"
                      @change="handleTagsCategories"
                    />
                  </div>
                </div>
              </div>
            </div>
        </div>

        <!-- Buttons Cancel & Ok -->
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="sendCloseDialog()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>CANCEL </span>
            </button>
            <button
              @click="sendOk()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span> <i class="zmdi zmdi-check"></i>SAVE </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  props: {
    openDialog: Boolean,
    dialogTitle: String,
    dataElement: Array,
    dialogWidth: String,
    tagsCategoriesValue: Array,
  },
  emits: ["closeDialog", "confirmData"],
  components:{
    Multiselect
  },
  data() {
    return {
    };
  },
  methods: {
    handleTagsCategories(option){
      let current_option = option[option.length - 1];
      if(current_option == 'all'){
        this.$emit("ChangeTagsCategoriesValue",['all']);
      }else{
        const tagsCategoriesValue =option.filter(option => option !== 'all') 
         this.$emit("ChangeTagsCategoriesValue",tagsCategoriesValue);
      }
    },
    sendCloseDialog() {
      this.$emit("closeDialog");
    },
    sendOk() {
      this.$emit("confirmChangeCategories");
    },
  },
};
</script>
