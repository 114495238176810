<template>
  <main>
    <div class="container content-container">
      <div class="align-items-center d-flex justify-content-center container p-0 m-0">
        <div class="header-text">
          <h1>Password Recovery</h1>
        </div>
      </div>
      <div class="align-items-center d-flex justify-content-center row mx-1">
        <div class="login-wrap col-md-7 col-lg-6">
          <form novalidate class="m-3" @submit.prevent="submitForgotPassword()">
            <div
              v-if="!isEmailSent"
              class="alert"
              style="color: #0b4974; background-color: #149efd; border-color: #68b3e6"
              role="alert"
            >
              Enter your <strong>email address</strong> to reset your password.<br />Check
              out your email and click on the link to reset your password.
            </div>
            <div
              v-if="isEmailSent"
              class="alert"
              style="color: #0b4974; background-color: #149efd; border-color: #68b3e6"
              role="alert"
            >
              An email has been sent to the following email address:<br /><strong>{{
                v$.form.email.$model
              }}</strong>
            </div>
            <!-- E-mail -->
            <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
              <label for="email" class="form-label">EMAIL</label>
              <input
                id="email"
                ref="email"
                v-model.trim="v$.form.email.$model"
                type="email"
                class="form-control"
                placeholder="Your email"
                required
                spellcheck="false"
                @click.right.prevent
                @keydown.space.prevent
                autocomplete="off"
                autocapitalize="none"
                :disabled="isEmailSent"
              />
            </div>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.email.$errors"
              :key="index"
            >
              <div class="error-msg mb-4">{{ error.$message }}</div>
            </div>

            <div class="row mt-4">
              <div class="col-md-12">
                <!-- Login Button -->
                <base-icon-button
                  :typeButton="'button'"
                  :alignClass="'float-start'"
                  @click.prevent="$router.push({ name: 'view-login' })"
                  ><i class="zmdi zmdi-sign-in"></i>Login</base-icon-button
                >
                <!-- Send Button -->
                <base-icon-button
                  v-if="!isEmailSent"
                  id="loginBtn"
                  ref="loginBtn"
                  :alignClass="'float-end'"
                  :typeButton="'submit'"
                  v-html="isLoading ? buttonCaption.wait : buttonCaption.send"
                ></base-icon-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import EventService from "@/services/EventService.js";
import BaseIconButton from "@/components/core/BaseIconButton.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";

export default {
  components: { BaseIconButton },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        email: "",
      },
      buttonCaption: {
        wait:
          '<span class="spinner-border spinner-border-sm" role="status"></span>&nbsp;Attendere',
        send: '<i class="zmdi zmdi-email"></i>Send',
      },
      isLoading: false,
      isEmailSent: false,
      spinnerLoader: null,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage("This field cannot be empty", required),
          email: helpers.withMessage("It's not a valid email", email),
        },
      },
    };
  },
  methods: {
    async submitForgotPassword() {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        this.focusInput();
        return false;
      }

      // Call Forgot Password API
      this.isLoading = true;
      this.showLoader(true);
      let apiResponseObj = await EventService.sendEmailResetPassword(
        this.v$.form.email.$model
      );
      this.isLoading = false;
      this.showLoader(false);

      //api error
      let pswError = false;
      if (apiResponseObj.status != 200) {
        //error password code
        switch (apiResponseObj.data.code) {
          case 104:
            console.log("Forgot password >> 104 >> Email doesn't exist");
            this.$root.addSnackBarMsg("Email doesn't exist", "error");
            pswError = true;
            break;
          default:
            console.log(
              "Forgot  password >> default >> error data:",
              apiResponseObj.data
            );
            this.$root.addSnackBarMsg(
              "FORGOT PASSWORD: An error has occurred, please try again",
              "error"
            );
            pswError = true;
            setTimeout(() => {
              this.focusInput();
            }, 200);
            return false;
        }
      }
      console.log("++ is error email forgot pasword:", pswError);

      if (pswError) return false;

      console.log("Forgot password >> 0 >> Email successfully sent");
      this.$root.addSnackBarMsg("Email successfully sent", "info");
      this.isEmailSent = true;
    },
    focusInput() {
      this.$refs.email.focus();
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  mounted() {
    this.focusInput();
  },
};
</script>
