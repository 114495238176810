import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Bootstrap 5
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
// Custom CSS
import "./assets/css/styles.css";
import "./assets/css/sweetalert2.css";
// Material Design Font Icon
import "./assets/css/material-design-iconic-font.min.css";
// Snackbar or Tiny Toast
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";
// Vue Sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//Vue loading
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// Vue Video Player
import VueVideoPlayer from "vue-video-player";
// require videojs style
import "video.js/dist/video-js.css";
//import "vue-video-player/src/custom-theme.css";

//Vue Plyr (video player)
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

// Quill Editor Rich Text
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

// CKEditor Rich Text
import CKEditor from "@ckeditor/ckeditor5-vue";

async function firstLoad() {
  setTimeout(() => {
    const app = createApp(App);
    // app.mixin(appGlobalVariables);
    app.config.globalProperties.$appGlobalVariables = {
      // BASEURL_IDENTITY: "http://accessocasa.ddns.net:15003",
      // BASEURL_API: "http://accessocasa.ddns.net:15002",
      // BASEURL_UPLOAD: "http://accessocasa.ddns.net:5003",
      // BASEURL_ENCODE: "http://accessocasa.ddns.net:62036",
      // BASEURL_WEBSOCKET: "http://accessocasa.ddns.net:15002/comm/lamberti",

      BASEURL_IDENTITY: process.env.VUE_APP_BASEURL_IDENTITY,
      BASEURL_API: process.env.VUE_APP_BASEURL_API,
      BASEURL_UPLOAD: process.env.VUE_APP_BASEURL_UPLOAD,
      BASEURL_ENCODE: process.env.VUE_APP_BASEURL_ENCODE,
      BASEURL_WEBSOCKET: process.env.VUE_APP_BASEURL_WEBSOCKET
    };
    app.use(store);
    app.use(router);
    app.use(VuePlyr);
    app.use(CKEditor);
    app.use(SnackbarService);
    app.use(VueSweetalert2);
    app.use(VueLoading, {
      container: null,
      canCancel: false,
      width: 64,
      height: 64,
      backgroundColor: "#000000",
      color: "#149efd",
      loader: "bars"
    });
    app.component("vue3-snackbar", Vue3Snackbar);
    app.component("QuillEditor", QuillEditor);
    app.use(
      VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
    );
    // Replace -> app.mount('#app')
    router.isReady().then(() => {
      app.mount("#app");
    });
  }, 1000);
}

firstLoad();
