import EventService from "@/services/EventService.js";
import moment from "moment";

const METADATA_DEFAULT_LANG = process.env.VUE_APP_DEFAULT_METADATA_LANG

export default {
  data() {
    return { spinnerLoader: null, containerFullPage: true };
  },
  //******* API Methods ******/
  //** Get Categories *//
  async callApi_getCategories() {
    let result = await EventService.getCategories();
    let output = { status: "", data: {} };
    switch (result.status) {
      case 200:
        output = { status: 200, data: result };
        console.log("callApi_getCategories >> OK", output);
        break;
      default:
        output = { status: "error", data: result };
        console.error("callApi_getCategories >> Server error >>", output);
        break;
    }
    return output;
  },
  //** Get SubCategories *//
  async callApi_getSubCategories(categoryId) {
    let result = await EventService.getSubCategories(categoryId);
    let output = { status: "", data: {} };
    switch (result.status) {
      case 200:
        output = { status: 200, data: result };
        console.log("callApi_getSubCategories >> OK", output);
        break;
      default:
        output = { status: "error", data: result };
        console.error("callApi_getSubCategories >> Server error >>", output);
        break;
    }
    return output;
  },
  //** Get All Videos *//
  async callApi_getAllVideosConsole(options, token) {
    let output = { status: null, data: {}, totVideos: {} };
    if (!options) {
      console.error("callApi_getAllVideosConsole >> no options");
      return output;
    }
    let page = options.options.page;
    let size = options.options.size;
    let category = options.options.category;
    let sort = options.options.sort;
    let result = await EventService.getAllVideosConsole(
      page,
      size,
      category,
      token,
      sort
    );
    switch (result.status) {
      case 200:
        console.log("callApi_getAllVideosConsole >> OK");
        output = {
          status: 200,
          data: result.data,
          totVideos: result.totVideos
        };
        break;
      default:
        console.error("callApi_getAllVideosConsole >> Server error >>", result);
        output = { status: "error", data: result, totVideos: {} };
        break;
    }
    return output;
  },
  //** Get Searched Videos *//
  async callApi_getSearchedVideos(options, token) {
    let output = { status: null, data: {}, totVideos: {} };
    if (!options) {
      console.error("callApi_getSearchedVideos >> no options");
      return output;
    }
    let page = options.options.page;
    let size = options.options.size;
    let textToSearch = options.options.textToSearch;
    let result = await EventService.getSearchedVideos(
      page,
      size,
      textToSearch,
      token
    );
    switch (result.status) {
      case 200:
        console.log("callApi_getSearchedVideos >> OK");
        output = {
          status: 200,
          data: result.data,
          totVideos: result.totVideos
        };
        break;
      default:
        console.error("callApi_getSearchedVideos >> Server error >>", result);
        output = { status: "error", data: result, totVideos: {} };
        break;
    }
    return output;
  },
  //** Get Favourite Videos *//
  async callApi_getFavouriteVideos(options, token) {
    let output = { status: null, data: {}, totVideos: {} };
    if (!options) {
      console.error("callApi_getFavouriteVideos >> no options");
      return output;
    }
    let page = options.options.page;
    let size = options.options.size;
    let result = await EventService.getFavouriteVideos(page, size, token);
    switch (result.status) {
      case 200:
        console.log("callApi_getFavouriteVideos >> OK");
        output = {
          status: 200,
          data: result.data,
          totVideos: result.totVideos
        };
        break;
      default:
        console.error("callApi_getFavouriteVideos >> Server error >>", result);
        output = { status: "error", data: result, totVideos: {} };
        break;
    }
    return output;
  },
  //** Get Video *//
  async callApi_getVideo(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.getVideo(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_getVideo >> OK");
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_getVideo >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Get Sharing Url *//
  async callApi_getSharingUrl(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.getSharingUrl(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_getSharingUrl >> OK");
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_getSharingUrl >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Put Video Views *//
  async callApi_putVideoAddView(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.putVideoAddView(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_putVideoAddView >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_putVideoAddView >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Set Favourite Video *//
  async callApi_setFavouriteVideo(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.setFavouriteVideo(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_setFavouriteVideo >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_setFavouriteVideo >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Unset Favourite Video *//
  async callApi_unsetFavouriteVideo(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.unsetFavouriteVideo(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_unsetFavouriteVideo >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_unsetFavouriteVideo >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Set Featured Video *//
  async callApi_setFeaturedVideo(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.setFeaturedVideo(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_setFeaturedVideo >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_setFeaturedVideo >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Unset Featured Video *//
  async callApi_unsetFeaturedVideo(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.unsetFeaturedVideo(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_unsetFeaturedVideo >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_unsetFeaturedVideo >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Set Visible Video *//
  async callApi_setVisibleVideo(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.setVisibleVideo(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_setVisibleVideo >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_setVisibleVideo >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Unset Visible Video *//
  async callApi_unsetVisibleVideo(videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.unsetVisibleVideo(videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_unsetVisibleVideo >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_unsetVisibleVideo >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Set Video Tags *//
  async callApi_setVideoTags(tags, videoId) {
    let output = { status: null, data: {} };
    if (!videoId) {
      return output;
    }
    let result = await EventService.setVideoTags(tags, videoId);
    switch (result.status) {
      case 200:
        console.log("callApi_setVideoTags >> OK", result.data);
        output = { status: 200, data: result.data };
        break;
      default:
        console.error("callApi_setVideoTags >> Server error >>", result);
        output = { status: "error" };
        break;
    }
    return output;
  },
  //** Get Searched Videos Console *//
  async callApi_getSearchedVideosConsole(options, token) {
    let output = { status: null, data: {}, totVideos: {} };
    if (!options) {
      console.error("callApi_getSearchedVideosConsole >> no options");
      return output;
    }
    let page = options.options.page;
    let size = options.options.size;
    let textToSearch = options.options.textToSearch;
    let category = options.options.category;
    let sort = options.options.sort;
    let result = await EventService.getSearchedConsoleVideos(
      page,
      size,
      textToSearch,
      category,
      sort,
      token
    );
    switch (result.status) {
      case 200:
        console.log("callApi_getSearchedVideosConsole >> OK");
        output = {
          status: 200,
          data: result.data,
          totVideos: result.totVideos
        };
        break;
      default:
        console.error(
          "callApi_getSearchedVideosConsole >> Server error >>",
          result
        );
        output = { status: "error", data: result, totVideos: {} };
        break;
    }
    return output;
  },
  //******* Execute Methods ******/
  async execute(options, token) {
    let output = { status: "error" };
    if (!options) {
      return output;
    }
    let data;
    switch (options.action) {
      // Get all videos
      case "get-all-videos":
        data = await this.callApi_getAllVideosConsole(options, token);
        output = this.outputResponseFromApi(data);
        break;
      // Get  searched videos
      case "get-searched-videos":
        data = await this.callApi_getSearchedVideos(options, token);
        output = this.outputResponseFromApi(data);
        break;
      // Get  favourite videos
      case "get-favourite-videos":
        data = await this.callApi_getSearchedVideos(options, token);
        output = this.outputResponseFromApi(data);
        break;
      default:
        output = { status: "error" };
        break;
    }
    return output;
  },
  //******* Methods ******/
  scrollToTop() {
    window.scrollTo(0, 0);
  },
  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },
  convertUnixTimestampToLocalTime(unixTimestamp) {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);

    //const humanDateFormat = dateObject.toLocaleString(); //2019-12-9 10:30:15
    dateObject.toLocaleString("it-IT", { weekday: "long" }); // Monday
    dateObject.toLocaleString("it-IT", { month: "long" }); // December
    dateObject.toLocaleString("it-IT", { day: "numeric" }); // 9
    dateObject.toLocaleString("it-IT", { year: "numeric" }); // 2019
    dateObject.toLocaleString("it-IT", { hour: "numeric" }); // 10 AM
    dateObject.toLocaleString("it-IT", { minute: "numeric" }); // 30
    dateObject.toLocaleString("it-IT", { second: "numeric" }); // 15
    return dateObject.toLocaleString("it-IT", { timeZoneName: "short" }).trim(); // 12/9/2019, 10:30:15 AM CST
  },
  convertUnixTimestampToLocalTimeGMT(unixTimestamp) {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    return (
      dateObject.toLocaleDateString("it-IT") +
      " " +
      dateObject.toLocaleTimeString("it-IT")
    );
    // return dateObject.toUTCString();
  },

  convertUTCDateToLocalTime(utcDate) {
    //const utcDate = "2022-05-23T16:19:04+00:00";
    const date = new Date(utcDate);
    //console.log(date);
    //console.log(date.toLocaleString("it-IT"));
    return date.toLocaleString().trim();
  },
  convertSecondsToTime(secs) {
    var date = new Date(null);
    date.setSeconds(secs);
    let result = date.toISOString().substr(11, 8);
    return result.trim();
  },
  formatBytes(bytes, decimals = 2) {
    if (isNaN(bytes)) return "0 Bytes";
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  formatNumberIT(n, numDecimali) {
    if (typeof n === "undefined" || n == null) return "-"; // nessun numero passato
    if (typeof n !== "number") throw "Not a number";
    if (typeof numDecimali === undefined) numDecimali = 0;
    var sepMigliaia = ".";
    var sepDecimali = ",";
    var strNumero = n.toString();
    var n1 = strNumero;
    var d1 = "";

    var i = strNumero.indexOf(".");
    if (i !== -1) {
      n1 = strNumero.substring(0, i);
      if (numDecimali > 0) {
        d1 =
          sepDecimali +
          (strNumero.substring(i + 1) + "000000000000000").substring(
            0,
            numDecimali
          );
      }
    }
    // migliaia
    var m = n1.length % 3;
    if (m === 0) m = 3;
    var s = "";
    i = 0;
    while (i < n1.length) {
      if (m === 0) {
        s += sepMigliaia;
        m = 3;
      }
      s += n1[i];
      i++;
      m--;
    }
    return s + d1;
  },
  outputResponseFromApi(data) {
    let output;
    if (data.status === 200) {
      output = { status: 200, data: data.data };
    } else {
      output = { status: "error" };
    }
    return output;
  },
  getDateShort(dateUTC, option = "") {
    let value = "Inesistente";
    if (dateUTC) {
      let createdDate = this.convertUTCDateToLocalTime(dateUTC).split(",");
      let shortDate = this.convertUTCDateToLocalTime(dateUTC);
      if (createdDate || createdDate.length > 0) {
        if (option == "date") {
          value = createdDate[0];
        } else if (option == "time") {
          value = createdDate[1];
        } else {
          value = shortDate;
        }
      }
    }
    return value.trim();
  },
  convertUTC(dateUTC) {
    let value = "N.A.";
    if (dateUTC) {
      let createdDate = this.convertUTCDateToLocalTime(dateUTC).split(",");
      if (createdDate || createdDate.length > 0) {
        value = createdDate[0] + " " + createdDate[1];
      }
    }
    return value.trim();
  },
  getViews(views) {
    let value = "0";
    if (views) {
      value = this.formatNumberIT(views);
    }
    return value.trim();
  },
  getFavourited(views) {
    let value = "0";
    if (views) {
      value = this.formatNumberIT(views);
    }
    return value.trim();
  },
  getTags(tags) {
    let value = "";
    if (!tags || tags.length == 0) return value;

    for (let i = 0; i < tags.length; i++) {
      if (i == tags.length - 1) {
        value += tags[i].name;
      } else {
        value += tags[i].name + ", ";
      }
    }
    return value.trim().toUpperCase().split(",");
  },
  getTagsFoVideoDetails(tags) {
    let value = "";
    let result = [];
    if (!tags || tags.length == 0) return result;
    for (let i = 0; i < tags.length; i++) {
      value = tags[i].subCategoryId;
      result.push(value);
    }
    return result;
  },
  getTagsOld(tags) {
    let value = "";
    if (tags || tags.length > 0) {
      for (let i = 0; i < tags.length; i++) {
        if (i == tags.length - 1) {
          value += tags[i].name;
        } else {
          value += tags[i].name + ", ";
        }
      }
    }
    return value.trim();
  },
  generateBreadcrumb(video) {
    let subTitle = null;
    if (video.duration != "" && video.created_time != "") {
      const videoDuration = this.convertSecondsToTime(video.duration);
      const videoCreatedTime = this.getDateShort(video.created_time);
      subTitle = {
        duration: videoDuration,
        created_time: videoCreatedTime
      };
    }
    return {
      title: video.title,
      details: subTitle
    };
  },
  addOverlayDiv() {
    let overlayDiv = document.createElement("div");
    overlayDiv.id = "overlay-div";
    overlayDiv.className = "overlay-div";
    document.getElementsByTagName("body")[0].appendChild(overlayDiv);
  },
  removeOverlayDiv() {
    document.getElementById("overlay-div").remove();
  },
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
  // Group array by key
  groupByKey(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  },
  // Grouping an array of objects using a key
  groupArrayByKey(array) {
    const obj = {};

    array.forEach(element => {
      if (!obj[element.catId]) {
        // create object if id is not recognized
        obj[element.catId] = {
          catId: element.catId,
          namesearch: element.namesearch,
          language: []
        };
      }
      // add language
      obj[element.catId].language.push({
        lang: element.lang,
        name: element.name
      });
    });
  },
  // Grouping an array of objects using a key and sorting
  groupArrayByKeySorted(array, key, sort = 0) {
    const obj = {};
    if (array != undefined) {
      if (array || array.length > 0) {
        array.forEach(element => {
          if (element.name != "") {
            if (!obj[element[key]]) {
              // create object if key is not recognized
              obj[element[key]] = {
                [key]: element[key],
                namesearch: element.namesearch,
                language: [],
                $sort: ""  //fixed  special character
              };
            }
            // add language
            obj[element[key]].language.push({
              lang: element.lang,
              name: element.name
            });

            // custom sort
            if (element.lang === METADATA_DEFAULT_LANG) {
              obj[element[key]].$sort = element.name;
            }
          }

        });
      }
    }

    //convert to object and sorted
    let arraySorted;
    if (sort == 0) {
      //sort A-Z
      arraySorted = Object.values(obj).sort((a, b) =>
        a.$sort.localeCompare(b.$sort, undefined, { sensitivity: 'base' }) //case insensitive
        //   a.$sort > b.$sort ? 1 : -1
      );
    } else if (sort == 1) {
      //sort Z-A
      arraySorted = Object.values(obj).sort((a, b) =>
        b.$sort.localeCompare(a.$sort, undefined, { sensitivity: 'base' }) //case insensitive
        // a.$sort > b.$sort ? -1 : 1
      );
    }

    console.log("** groupArrayByKeySorted:", key, arraySorted);

    return arraySorted;
  },
  //Return simplyfied categories
  getSemplifyCategories(elementArray) {
    let categories = [];
    let obj = [];
    console.log("Object.keys(elementArray)", Object.keys(elementArray));
    if (Object.keys(elementArray).length > 0) {
      for (let i = 0; i < Object.keys(elementArray).length; i++) {
        obj = elementArray[i].language;
        obj.forEach(element => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            categories.push({
              catId: elementArray[i].catId,
              name: element.name
            });
          }
        });
      }
    }
    console.log("++ getSemplifyCategories:", categories);
    return categories;
  },
  //Return semplified Attributes with id and name
  getSemplifyAttributes(elementArray, key) {
    let attributes = [];
    let obj = [];
    if (Object.keys(elementArray).length > 0) {
      for (let i = 0; i < Object.keys(elementArray).length; i++) {
        obj = elementArray[i].language;
        obj.forEach(element => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            attributes.push({
              [key]: elementArray[i][key],
              name: element.name,
              lang: METADATA_DEFAULT_LANG,
              namesearch: elementArray[i].namesearch
            });
          }
        });
      }
    }
    console.log("++ getSemplifyAttributes: " + key, attributes);
    return attributes;
  },
  //Convert UNIX Time Stamp with moment
  convertUnixTimeStampMoment(inputDate) {
    let convertedInputDate = "n.a.";
    if (inputDate > 0) {
      convertedInputDate = moment.unix(inputDate).format("DD/MM/YYYY HH:mm:ss");
    }
    return convertedInputDate;
  },
  //Spinner loader
  showLoader(isShow) {
    if (isShow) {
      this.spinnerLoader = this.$loading.show({
        // Optional parameters
        container: this.containerFullPage ? null : null,
        canCancel: false,
        width: 64,
        height: 64,
        backgroundColor: "#000000",
        color: "#149efd",
        loader: "bars"
      });
    } else {
      this.spinnerLoader.hide();
    }
  },
  generateUID() {
    //easy
    // const uid =
    //   Date.now().toString(36) + Math.random().toString(36).substring(2);
    //complex and fixed length
    const uid =
      Math.floor(
        Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
      ).toString(36) + Date.now().toString(36);
    return uid.toUpperCase();
  },
  generateElementUID() {
    //easy
    // const uid = new Date().getTime();
    const date = new Date();
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  },
  uid() {
    let a = new Uint32Array(3);
    //not fixed length
    window.crypto.getRandomValues(a);
    return (
      performance.now().toString(36) +
      Array.from(a)
        .map(A => A.toString(36))
        .join("")
    )
      .replace(/\./g, "")
      .substring(9);
  },
  generateGUID() {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  },
  compareValuesObjKey(key, order = "asc") {
    return function innerSort(a, b) {
      if (
        !Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)
      ) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  },
  generateUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
};
