<template>
  <transition name="backdrop-modal">
    <div v-if="openDialog" class="backdrop-dialog"></div>
  </transition>
  <transition name="modal">
    <div
      class="dialog background-gray"
      :style="`width: ${dialogWidth}`"
      v-if="openDialog"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <div class="row">
          <div class="accordion-body">
            <!-- Dialog Content -->
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <!-- Language EN RU Tabs -->
                  <div class="row m-0 padding-x-4px p-2">
                    <ul class="nav nav-tabs language-tabs">
                      <li class="nav-item">
                        <a href="#lang_en" class="nav-link active" data-bs-toggle="tab"
                          >EN</a
                        >
                      </li>
                      <li v-if="is_RU_LangAvailable" class="nav-item">
                        <a href="#lang_ru" class="nav-link" data-bs-toggle="tab">RU</a>
                      </li>
                    </ul>
                    <div class="tab-content p-0">
                      <!-- Language EN -->
                      <div class="tab-pane fade show active" id="lang_en">
                        <div class="container-fluid m-0 p-0">
                          <!-- Content Language EN -->
                          <div class="container-fluid p-0">
                            <!-- Name -->
                            <div class="align-items-center d-flex my-2 row">
                              <div class="col-lg-3 pe-0">
                                <div class="form-label my-2">NAME*</div>
                              </div>
                              <div class="align-items-center col-lg-9 d-flex flex-row">
                                <input
                                  type="text"
                                  class="form-control"
                                  spellcheck="false"
                                  placeholder="Name"
                                  v-model="model_name_en"
                                  @input="handleNameEn($event)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Language RU -->
                      <div class="tab-pane fade" id="lang_ru">
                        <div class="container-fluid m-0 p-0">
                          <!-- Content Language RU -->
                          <div class="container-fluid p-0">
                            <!-- Name -->
                            <div class="align-items-center d-flex my-2 row">
                              <div class="col-lg-3 pe-0">
                                <div class="form-label my-2">NAME</div>
                              </div>
                              <div class="align-items-center col-lg-9 d-flex flex-row">
                                <input
                                  type="text"
                                  class="form-control"
                                  spellcheck="false"
                                  placeholder="Name"
                                  v-model="model_name_ru"
                                  @input="handleNameRu($event)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <!-- Language IT Tabs -->
                  <div class="row m-0 padding-x-4px p-2">
                    <ul class="nav nav-tabs language-tabs">
                      <li class="nav-item">
                        <a href="#lang_it" class="nav-link active" data-bs-toggle="tab"
                          >IT</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content p-0">
                      <!-- Language IT -->
                      <div class="tab-pane fade show active" id="lang_it">
                        <div class="container-fluid m-0 p-0">
                          <!-- Content Language IT -->
                          <div class="container-fluid p-0">
                            <!-- Name -->
                            <div class="align-items-center d-flex my-2 row">
                              <div class="col-lg-3 pe-0">
                                <div class="form-label my-2">NAME</div>
                              </div>
                              <div class="align-items-center col-lg-9 d-flex flex-row">
                                <input
                                  type="text"
                                  class="form-control"
                                  spellcheck="false"
                                  placeholder="Name"
                                  v-model="model_name_it"
                                  @input="handleNameIt($event)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Buttons Cancel & Ok -->
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="sendCloseDialog()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>Cancel </span>
            </button>
            <button
              @click="sendOk()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span> <i class="zmdi zmdi-check"></i>Ok </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// ricodarsi di cambiare "categories e catId" in "tags e tagId" e "areas e areaId"

import CommonMethods from "@/services/CommonMethods";

export default {
  props: {
    openDialog: Boolean,
    dialogTitle: String,
    dataElement: Object,
    dialogWidth: String,
    attribute: String,
    isEdit: Boolean,
  },
  emits: ["closeDialog", "confirmData"],
  components: {},
  data() {
    return {
      is_RU_LangAvailable: false,
      model_name_it: "",
      model_name_en: "",
      model_name_ru: "",
      languagesArray: [],
    };
  },
  methods: {
    sendCloseDialog() {
      this.$emit("closeDialog");
    },
    sendOk() {
      if (!this.validationFields()) return false;
      let attId = this.getKeyId();
      let dataElementModified;
      if (this.isEdit) {
        this.saveNewModelLanguages();
        dataElementModified = {
          [attId]: this.dataElement.dataElement[attId],
          namesearch: this.model_name_en.toLowerCase(),
          language: this.languagesArray,
          $sort: this.model_name_en,
        };
      } else {
        this.languagesArray = [
          { lang: "it", name: "" },
          { lang: "en", name: "" },
          { lang: "ru", name: "" },
        ];
        this.saveNewModelLanguages();
        let id = CommonMethods.generateElementUID().toString();
        dataElementModified = {
          [attId]: id,
          namesearch: this.model_name_en.toLowerCase(),
          language: this.languagesArray,
          $sort: this.model_name_en,
        };
      }

      let objReturn = {
        dataElement: dataElementModified,
        index: this.dataElement.index,
      };

      console.log("++ attribute objReturn", objReturn);
      this.$emit("confirmData", objReturn);
    },
    validationFields() {
      if (this.model_name_en == "") {
        this.$root.addSnackBarMsg("NAME: This field cannot be empty", "warning");
        return false;
      }
      return true;
    },
    init() {
      this.model_name_it = "";
      this.model_name_en = "";
      this.model_name_ru = "";
    },
    handleNameIt(event) {
      console.log("++ handle name it:", event.target.value);
    },
    handleNameEn(event) {
      console.log("++ handle name en:", event.target.value);
    },
    handleNameRu(event) {
      console.log("++ handle name ru:", event.target.value);
    },
    initModelLanguages() {
      this.languagesArray = this.dataElement.dataElement.language;
      console.log("++ initModelLanguages", this.languagesArray);
      for (let i = 0; i < this.languagesArray.length; i++) {
        if (this.languagesArray[i].lang == "it") {
          this.model_name_it = this.languagesArray[i].name;
        }
        if (this.languagesArray[i].lang == "en") {
          this.model_name_en = this.languagesArray[i].name;
        }
        if (this.languagesArray[i].lang == "ru") {
          this.model_name_ru = this.languagesArray[i].name;
        }
      }
    },
    saveNewModelLanguages() {
      for (let i = 0; i < this.languagesArray.length; i++) {
        if (this.languagesArray[i].lang == "it") {
          this.languagesArray[i].name = this.model_name_it;
        }
        if (this.languagesArray[i].lang == "en") {
          this.languagesArray[i].name = this.model_name_en;
        }
        if (this.languagesArray[i].lang == "ru") {
          this.languagesArray[i].name = this.model_name_ru;
        }
      }
    },
    getKeyId() {
      let attId = "catId";
      if (this.attribute == "category") {
        attId = "catId";
      } else if (this.attribute == "tag") {
        attId = "tagId";
      } else if (this.attribute == "area") {
        attId = "areaId";
      }
      return attId;
    },
  },
  updated() {
    if (this.openDialog) {
      if (this.isEdit) {
        this.initModelLanguages();
      } else {
        this.init();
      }
      console.log("++ edit attribute element:", this.languagesArray);
      console.warn("++ this.attribute:", this.attribute);
    }
  },
};
</script>
