<template>
  <transition name="backdrop-modal">
    <div v-if="openDialog" class="backdrop-dialog"></div>
  </transition>
  <transition name="modal">
    <div
      class="dialog background-gray"
      :style="`width: ${dialogWidth}`"
      v-if="openDialog"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="header-text w-100">
            <h1>{{ dialogTitle }}</h1>
          </div>
        </div>
        <hr class="sepGray" />
        <!-- Dialog Content -->
        <div class="container-fluid p-0 m-0">
          <!-- Marker -->
          <div class="row p-1 mb-4">
            <!-- Video -->
            <div class="col-md-6 mb-3">
              <div class="form-label mb-1">
                VIDEO - DURATION (hh:mm:ss)
                <span class="text-white">{{
                  formatDuration(dataMarker.video_duration)
                }}</span>
              </div>
              <div class="border-mainblue bg-black">
                <!-- Video Player Plyr -->
                <vue-plyr ref="plyrPlayer_Marker" :options="options" class="m-0 p-0">
                  <video
                    class="m-0 p-0 w-100"
                    ref="video_marker"
                    controls
                    crossorigin
                    playsinline
                    :data-poster="dataMarker.video_poster.uri"
                  >
                    <!-- Video track -->
                    <source :src="dataMarker.video_src" type="video/mp4" />
                  </video>
                </vue-plyr>
              </div>
              <!-- Dual range slider -->
              <Slider
                v-model="valueSliderRange"
                :max="dataMarker.video_duration"
                :format="timeStringSliderRange"
                :lazy="lazySliderRange"
                @update="sliderRangeUpdate"
                class="my-5"
              />
              <!-- Button Test Marker -->
              <div class="container-fluid p-0 mt-3">
                <button
                  @click="testMarker"
                  type="button"
                  class="btn button-outline-1 small"
                >
                  <span> <i class="zmdi zmdi-time"></i>Test Marker </span>
                </button>
              </div>
            </div>

            <!-- Marker Properties -->
            <div class="col-md-6 mb-3">
              <div class="form-label mb-1">MARKER PROPERTIES</div>
              <!-- Row times -->
              <div class="row m-0 mt-4">
                <!-- Seek To -->
                <div
                  class="col-sm-12 d-flex flex-column justify-content-center p-0 mb-4 small"
                >
                  <div class="form-label mb-1">Seek To</div>
                  <div class="container d-flex m-0 p-0">
                    <Datepicker
                      @cleared="clearValue('seek')"
                      @update:modelValue="handleSeek"
                      class="w-100"
                      v-model="seekTo"
                      dark
                      timePicker
                      enableSeconds
                      is24
                      ref="seektoRef"
                    >
                      <template #input-icon>
                        <i class="input-slot-image zmdi zmdi-time"></i>
                      </template>
                      <template #clear-icon="{ clear }">
                        <i @click="clear" class="input-slot-image zmdi zmdi-close"></i>
                      </template>
                    </Datepicker>
                    <button
                      @click="setCurrentTime('seek')"
                      class="timepicker-button"
                      alt="SET CURRENT TIME"
                      title="SET CURRENT TIME"
                    >
                      <i class="zmdi zmdi-time zmdi-hc-lg"></i>
                    </button>
                  </div>
                </div>
                <!-- Start Time -->
                <div
                  class="col-sm-6 d-flex flex-column justify-content-center p-0 pe-2 small"
                >
                  <div class="form-label mb-1">Start Time</div>
                  <div class="container d-flex m-0 p-0">
                    <Datepicker
                      @cleared="clearValue('start')"
                      @update:modelValue="handleStartTime"
                      class="w-100"
                      v-model="startTime"
                      dark
                      timePicker
                      enableSeconds
                      is24
                      ref="starttimeRef"
                    >
                      <template #input-icon>
                        <i class="input-slot-image zmdi zmdi-time"></i>
                      </template>
                      <template #clear-icon="{ clear }">
                        <i @click="clear" class="input-slot-image zmdi zmdi-close"></i>
                      </template>
                    </Datepicker>
                    <button
                      @click="setCurrentTime('start')"
                      class="timepicker-button"
                      alt="SET CURRENT TIME"
                      title="SET CURRENT TIME"
                    >
                      <i class="zmdi zmdi-time zmdi-hc-lg"></i>
                    </button>
                  </div>
                </div>
                <!-- End Time -->
                <div
                  class="col-sm-6 d-flex flex-column justify-content-center p-0 ps-2 small"
                >
                  <div class="form-label mb-1">End Time</div>
                  <div class="container d-flex m-0 p-0">
                    <Datepicker
                      @cleared="clearValue('end')"
                      @update:modelValue="handleEndTime"
                      class="w-100"
                      v-model="endTime"
                      dark
                      timePicker
                      enableSeconds
                      is24
                      ref="endtimeRef"
                    >
                      <template #input-icon>
                        <i class="input-slot-image zmdi zmdi-time"></i>
                      </template>
                      <template #clear-icon="{ clear }">
                        <i @click="clear" class="input-slot-image zmdi zmdi-close"></i>
                      </template>
                    </Datepicker>
                    <button
                      @click="setCurrentTime('end')"
                      class="timepicker-button"
                      alt="SET CURRENT TIME"
                      title="SET CURRENT TIME"
                    >
                      <i class="zmdi zmdi-time zmdi-hc-lg"></i>
                    </button>
                  </div>
                </div>
                <!-- Published -->
                <div class="container mt-4 p-0">
                  <div class="form-check form-switch">
                    <input
                      ref="marker_published"
                      class="form-check-input"
                      type="checkbox"
                      id="check-published"
                      :checked="modelPublished"
                    />
                    <label class="content-form" for="check-published">Published</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="sepGray" />
          <!-- MetaDataDetails -->
          <div class="row m-0">
            <div class="form-label my-2 mb-3">METADATA</div>
            <div class="col-lg-6">
              <div class="row mb-2">
                <!-- Language EN RU Tabs -->
                <div class="row m-0 padding-x-4px p-2">
                  <ul class="nav nav-tabs language-tabs">
                    <li class="nav-item">
                      <a
                        href="#marker_lang_en"
                        class="nav-link active"
                        data-bs-toggle="tab"
                        >EN</a
                      >
                    </li>
                    <li v-if="is_RU_LangAvailable" class="nav-item">
                      <a href="#marker_lang_ru" class="nav-link" data-bs-toggle="tab"
                        >RU</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content p-0">
                    <!-- Language EN -->
                    <div class="tab-pane fade show active" id="marker_lang_en">
                      <div class="container-fluid m-0 p-0">
                        <!-- Content Language EN -->
                        <div class="container p-0">
                          <!-- Title -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="col-lg-3 pe-0">
                              <div class="form-label my-2">TITLE *</div>
                            </div>
                            <div class="align-items-center col-lg-9 d-flex flex-row">
                              <input
                                type="text"
                                class="form-control"
                                spellcheck="false"
                                v-model.trim="metaDataDetails_en.metaDataDetails.title"
                                placeholder="Title"
                              />
                            </div>
                          </div>
                          <!-- Description -->
                          <div class="align-items-top d-flex my-2 row">
                            <div class="col-lg-3 pe-0">
                              <div class="form-label my-2">DESCRIPTION</div>
                            </div>
                            <div class="align-items-center col-lg-9 d-flex flex-row">
                              <!-- <input
                                type="text"
                                class="form-control"
                                spellcheck="false"
                                v-model.trim="metaDataDetails_en.metaDataDetails.description"
                                placeholder="Description"
                              />-->
                              <!-- <text-area
                                v-model:content="metaDataDetails_en.metaDataDetails.description"
                                placeholder="Description"
                              ></text-area>-->
                              <div class="container m-0 p-0">
                                <ckeditor
                                  class="w-100"
                                  :editor="editor"
                                  v-model="metaDataDetails_en.metaDataDetails.description"
                                  :config="editorConfigAddMarker"
                                  @ready="onReady"
                                ></ckeditor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Language RU -->
                    <div class="tab-pane fade" id="marker_lang_ru">
                      <div class="container-fluid m-0 p-0">
                        <!-- Content Language RU -->
                        <div class="container p-0">
                          <!-- Title -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="col-lg-3 pe-0">
                              <div class="form-label my-2">TITLE</div>
                            </div>
                            <div class="align-items-center col-lg-9 d-flex flex-row">
                              <input
                                type="text"
                                class="form-control"
                                spellcheck="false"
                                v-model.trim="metaDataDetails_ru.metaDataDetails.title"
                                placeholder="Title"
                              />
                            </div>
                          </div>
                          <!-- Description -->
                          <div class="align-items-top d-flex my-2 row">
                            <div class="col-lg-3 pe-0">
                              <div class="form-label my-2">DESCRIPTION</div>
                            </div>
                            <div class="align-items-center col-lg-9 d-flex flex-row">
                              <!-- <input
                                type="text"
                                class="form-control"
                                spellcheck="false"
                                v-model.trim="metaDataDetails_ru.metaDataDetails.description"
                                placeholder="Description"
                              />-->
                              <!-- <text-area
                                v-model:content="metaDataDetails_ru.metaDataDetails.description"
                                placeholder="Description"
                              ></text-area>-->
                              <div class="container m-0 p-0">
                                <ckeditor
                                  class="w-100"
                                  :editor="editor"
                                  v-model="metaDataDetails_ru.metaDataDetails.description"
                                  :config="editorConfigAddMarker"
                                  @ready="onReady"
                                ></ckeditor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row mb-2">
                <!-- Language IT Tabs -->
                <div class="row m-0 padding-x-4px p-2">
                  <ul class="nav nav-tabs language-tabs">
                    <li class="nav-item">
                      <a
                        href="#marker_lang_it"
                        class="nav-link active"
                        data-bs-toggle="tab"
                        >IT</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content p-0">
                    <!-- Language IT -->
                    <div class="tab-pane fade show active" id="marker_lang_it">
                      <div class="container-fluid m-0 p-0">
                        <!-- Content Language IT -->
                        <div class="container p-0">
                          <!-- Title -->
                          <div class="align-items-center d-flex my-2 row">
                            <div class="col-lg-3 pe-0">
                              <div class="form-label my-2">TITLE</div>
                            </div>
                            <div class="align-items-center col-lg-9 d-flex flex-row">
                              <input
                                type="text"
                                class="form-control"
                                spellcheck="false"
                                v-model.trim="metaDataDetails_it.metaDataDetails.title"
                                placeholder="Title"
                              />
                            </div>
                          </div>
                          <!-- Description -->
                          <div class="align-items-top d-flex my-2 row">
                            <div class="col-lg-3 pe-0">
                              <div class="form-label my-2">DESCRIPTION</div>
                            </div>
                            <div class="align-items-center col-lg-9 d-flex flex-row">
                              <!-- <input
                                type="text"
                                class="form-control"
                                spellcheck="false"
                                v-model.trim="metaDataDetails_it.metaDataDetails.description"
                                placeholder="Title"
                              />-->
                              <!-- <rich-text-editor v-model="testDesc" customToolbar="ct12"></rich-text-editor> -->
                              <!-- <rich-text-editor
                                :contentTxt="metaDataDetails_it.metaDataDetails.description"
                                @reciveUpdateText="updateEditMarkerDescription_it"
                                customToolbar="ct12"
                              ></rich-text-editor>-->
                              <!-- <text-area
                                v-model:content="metaDataDetails_it.metaDataDetails.description"
                                placeholder="Description"
                              ></text-area>-->
                              <div class="container m-0 p-0">
                                <ckeditor
                                  class="w-100"
                                  :editor="editor"
                                  v-model="metaDataDetails_it.metaDataDetails.description"
                                  :config="editorConfigAddMarker"
                                  @ready="onReady"
                                ></ckeditor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Buttons Cancel & Ok -->
        <hr class="sepGray" />
        <div class="row">
          <div class="container-fluid my-3">
            <button
              @click="sendCloseDialog()"
              type="button"
              class="btn button-outline-1 float-start"
            >
              <span> <i class="zmdi zmdi-close"></i>Cancel </span>
            </button>
            <button
              @click="sendOk()"
              type="button"
              class="btn button-outline-1 float-end"
            >
              <span> <i class="zmdi zmdi-check"></i>Ok </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import RichTextEditor from "@/components/ui/RichTextEditor.vue";
// import TextArea from "@/components/ui/TextArea.vue";
import Slider from "@vueform/slider";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  props: {
    openDialog: Boolean,
    dialogTitle: String,
    dataMarker: Object,
    dialogWidth: String,
  },
  emits: ["closeDialog", "confirmData"],
  components: {
    Datepicker,
    // RichTextEditor,
    // TextArea,
    Slider,
  },
  data() {
    return {
      is_RU_LangAvailable: false,
      // editor: ClassicEditor,
      editor: DecoupledEditor,
      editorConfigAddMarker: {
        language: "it",
        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "underline",
            "link",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "indent",
            "outdent",
          ],
          shouldNotGroupWhenFull: false,
        },
        link: {
          // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
          addTargetToExternalLinks: true,
        },
      },
      valueSliderRange: [0, 0],
      lazySliderRange: false,
      timeStringSliderRange: function (timeInSec) {
        let milliseconds = timeInSec * 1000;
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;
        let timeString =
          hours.toString().padStart(2, "0") +
          ":" +
          minutes.toString().padStart(2, "0") +
          ":" +
          seconds.toString().padStart(2, "0");
        return timeString;
      },
      testDesc: "descrizione di test",
      originalMarkerData: {},
      modelPublished: false,
      originalMetaDataDetails_it: {
        title: "",
        description: "",
      },
      originalMetaDataDetails_en: {
        title: "",
        description: "",
      },
      originalMetaDataDetails_ru: {
        title: "",
        description: "",
      },
      metaDataDetails_it: {
        lang: "it",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },
      metaDataDetails_en: {
        lang: "en",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },
      metaDataDetails_ru: {
        lang: "ru",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      },
      currentTime: 0,
      options: {},
      time: {
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
        seconds: new Date().getSeconds(),
      },
      markerData: {},
      seekToInSec: 0,
      startTimeInSec: 0,
      endTimeInSec: 0,
      seekTo: { hours: 0, minutes: 0, seconds: 0 },
      startTime: { hours: 0, minutes: 0, seconds: 0 },
      endTime: { hours: 0, minutes: 0, seconds: 0 },
    };
  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    sliderRangeUpdate(event) {
      this.startTimeInSec = event[0];
      this.endTimeInSec = event[1];
      this.startTime = this.convertTimeInSecToTimeObj(this.startTimeInSec);
      this.endTime = this.convertTimeInSecToTimeObj(this.endTimeInSec);
    },
    sendCloseDialog() {
      //restore original values it
      this.metaDataDetails_it.metaDataDetails.title = this.originalMetaDataDetails_it.title;
      this.metaDataDetails_it.metaDataDetails.description = this.originalMetaDataDetails_it.description;
      //restore original values en
      this.metaDataDetails_en.metaDataDetails.title = this.originalMetaDataDetails_en.title;
      this.metaDataDetails_en.metaDataDetails.description = this.originalMetaDataDetails_en.description;
      //restore original values ru
      this.metaDataDetails_ru.metaDataDetails.title = this.originalMetaDataDetails_ru.title;
      this.originalMetaDataDetails_ru.description = this.metaDataDetails_ru.metaDataDetails.description;

      this.$emit("closeDialog");
    },
    sendOk() {
      let objReturn = {};
      const checkTimes = this.validationTimes();
      if (!checkTimes) return false;

      if (this.metaDataDetails_en.metaDataDetails.title == "") {
        console.error(
          "** this.metaDataDetails_en.metaDataDetails.title: is required. This field cannot be empty"
        );
        this.$root.addSnackBarMsg(
          "METADATA EN TITLE: This field cannot be empty",
          "warning"
        );
        return false;
      }

      objReturn = this.generateObjToReturn();

      let objToReturn = {
        index: this.dataMarker.index,
        marker: objReturn,
      };

      this.$emit("confirmData", objToReturn);
    },
    updateEditMarkerDescription_it(htmlValue) {
      this.metaDataDetails_it.metaDataDetails.description = htmlValue;
    },
    updateEditMarkerDescription_en(htmlValue) {
      this.metaDataDetails_en.metaDataDetails.description = htmlValue;
    },
    updateEditMarkerDescription_ru(htmlValue) {
      this.metaDataDetails_ru.metaDataDetails.description = htmlValue;
    },
    generateObjToReturn() {
      let objReturn = {};
      objReturn = this.originalMarkerData;
      objReturn.published = this.$refs.marker_published.checked;
      objReturn.initTimeSpan = this.convertTimeObjInTimeString(this.startTime);
      objReturn.endTimeSpan = this.convertTimeObjInTimeString(this.endTime);
      objReturn.seekTimeSpan = this.convertTimeObjInTimeString(this.seekTo);
      const objMetaIt = {
        lang: "it",
        metaDataDetails: {
          title: this.metaDataDetails_it.metaDataDetails.title,
          description: this.metaDataDetails_it.metaDataDetails.description,
          author: "",
          s_abstract: "",
          eyelet: "",
        },
      };
      const objMetaEn = {
        lang: "en",
        metaDataDetails: {
          title: this.metaDataDetails_en.metaDataDetails.title,
          description: this.metaDataDetails_en.metaDataDetails.description,
          author: "",
          s_abstract: "",
          eyelet: "",
        },
      };
      const objMetaRu = {
        lang: "ru",
        metaDataDetails: {
          title: this.metaDataDetails_ru.metaDataDetails.title,
          description: this.metaDataDetails_ru.metaDataDetails.description,
          author: "",
          s_abstract: "",
          eyelet: "",
        },
      };
      objReturn.metaDataDetails = [objMetaIt, objMetaEn, objMetaRu];

      return objReturn;
    },
    resetTimeValues(who = "all") {
      if (who === "all") {
        this.seekTo = { hours: 0, minutes: 0, seconds: 0 };
        this.startTime = { hours: 0, minutes: 0, seconds: 0 };
        this.endTime = { hours: 0, minutes: 0, seconds: 0 };
        this.seekToInSec = 0;
        this.startTimeInSec = 0;
        this.endTimeInSec = 0;
        return;
      }
      if (who === "seek") {
        this.seekTo = { hours: 0, minutes: 0, seconds: 0 };
        this.seekToInSec = 0;
        return;
      }
      if (who === "start") {
        this.startTime = { hours: 0, minutes: 0, seconds: 0 };
        this.startTimeInSec = 0;
        return;
      }
      if (who === "end") {
        this.endTime = { hours: 0, minutes: 0, seconds: 0 };
        this.endTimeInSec = 0;
        return;
      }
    },
    initMetadataDetails() {
      this.metaDataDetails_it = {
        lang: "it",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };
      this.metaDataDetails_en = {
        lang: "en",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };
      this.metaDataDetails_ru = {
        lang: "ru",
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };
    },
    initTimeValues() {
      this.seekTo = this.converTimeStringInTimeObj(this.originalMarkerData.seekTimeSpan);
      this.startTime = this.converTimeStringInTimeObj(
        this.originalMarkerData.initTimeSpan
      );
      this.endTime = this.converTimeStringInTimeObj(this.originalMarkerData.endTimeSpan);
      this.seekToInSec = this.convertTimeObjInSecs(this.seekTo);
      this.startTimeInSec = this.convertTimeObjInSecs(this.startTime);
      this.endTimeInSec = this.convertTimeObjInSecs(this.endTime);
      this.valueSliderRange[0] = this.startTimeInSec;
      this.valueSliderRange[1] = this.endTimeInSec;
    },
    clearValue(who) {
      this.resetTimeValues(who);
    },
    testMarker() {
      this.$refs.plyrPlayer_Marker.player.pause();
      this.gotoMarker(this.convertTimeObjInSecs(this.startTime));
    },
    gotoMarker(timeInSec) {
      this.$refs.plyrPlayer_Marker.player.currentTime = timeInSec;
    },
    getSingleMarkerMetadataDetails(langToSearch) {
      let elementToFind;
      let objToReturn = {
        lang: langToSearch,
        metaDataDetails: {
          title: "",
          author: "",
          s_abstract: "",
          eyelet: "",
          description: "",
        },
      };
      const arrMetadata = this.originalMarkerData.metaDataDetails;
      elementToFind = arrMetadata.find((el) => el.lang === langToSearch);
      console.log("+++ elementToFind", elementToFind);
      if (elementToFind) {
        objToReturn = elementToFind;
      }
      return objToReturn;
    },
    setCurrentTime(who) {
      if (who === "seek") {
        this.seekTo = this.convertTimeInSecToTimeObj(
          this.$refs.plyrPlayer_Marker.player.currentTime
        );
        this.seekToInSec = this.$refs.plyrPlayer_Marker.player.currentTime;
        // this.validationTimes();
        return;
      }
      if (who === "start") {
        this.startTime = this.convertTimeInSecToTimeObj(
          this.$refs.plyrPlayer_Marker.player.currentTime
        );
        this.startTimeInSec = this.$refs.plyrPlayer_Marker.player.currentTime;
        // this.validationTimes();
        this.valueSliderRange[0] = this.startTimeInSec;
        return;
      }
      if (who === "end") {
        this.endTime = this.convertTimeInSecToTimeObj(
          this.$refs.plyrPlayer_Marker.player.currentTime
        );
        this.endTimeInSec = this.$refs.plyrPlayer_Marker.player.currentTime;
        // this.validationTimes();
        this.valueSliderRange[1] = this.endTimeInSec;
        return;
      }
    },
    handleSeek(value) {
      this.seekToInSec = this.convertTimeObjInSecs(value);
      // this.validationTimes();
      console.log("seekTo:", value, this.seekToInSec);
    },
    handleStartTime(value) {
      this.startTimeInSec = this.convertTimeObjInSecs(value);
      this.valueSliderRange[0] = this.startTimeInSec;
      // this.validationTimes();
      console.log("startTime:", value, this.startTimeInSec);
    },
    handleEndTime(value) {
      this.endTimeInSec = this.convertTimeObjInSecs(value);
      this.valueSliderRange[1] = this.endTimeInSec;
      // this.validationTimes();
      console.log("endTime:", value, this.endTimeInSec);
    },
    validationTimes() {
      const videoDuration = this.dataMarker.video_duration;
      if (videoDuration > 0) {
        if (this.seekToInSec > videoDuration) {
          this.$root.addSnackBarMsg(
            "'Seek To' cannot be greater than the length of the video",
            "warning"
          );
          return false;
        }
        if (this.startTimeInSec > videoDuration) {
          this.$root.addSnackBarMsg(
            "'Start Time' cannot be greater than the length of the video",
            "warning"
          );
          return false;
        }
        if (this.endTimeInSec > videoDuration) {
          this.$root.addSnackBarMsg(
            "'End Time' cannot be greater than the length of the video",
            "warning"
          );
          return false;
        }
        if (this.startTimeInSec >= this.endTimeInSec) {
          this.$root.addSnackBarMsg(
            "'Start Time' cannot be greater or equal than 'End Time'",
            "warning"
          );
          return false;
        }
        if (this.endTimeInSec <= this.startTimeInSec) {
          this.$root.addSnackBarMsg(
            "'End Time' must be greater or equal than 'Start Time'",
            "warning"
          );
          return false;
        }
        if (this.seekToInSec === 0) {
          this.$root.addSnackBarMsg("'Seek To' must be greater than zero", "warning");
          return false;
        }
        return true;
      } else {
        this.$root.addSnackBarMsg("Inconsistency error: video duration is 0", "error");
        return false;
      }
    },
    formatDuration(duration) {
      let milliseconds = duration * 1000;
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      seconds = seconds % 60;
      minutes = minutes % 60;
      hours = hours % 24;
      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(
        minutes
      )}:${this.padTo2Digits(seconds)}`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    convertTimeStringInSec(timeString) {
      let column = timeString.split(":");
      let seconds = +column[0] * 60 * 60 + +column[1] * 60 + +column[2];
      return seconds;
    },
    converTimeStringInTimeObj(timeString) {
      let column = timeString.split(":");
      return {
        hours: column[0],
        minutes: column[1],
        seconds: column[2],
      };
    },
    convertTimeObjInSecs(timeObj) {
      if (timeObj == null) return 0;
      let seconds = +timeObj.hours * 60 * 60 + +timeObj.minutes * 60 + +timeObj.seconds;
      return seconds;
    },
    convertTimeInSecToTimeObj(timeInSec) {
      let milliseconds = timeInSec * 1000;
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      seconds = seconds % 60;
      minutes = minutes % 60;
      hours = hours % 24;
      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    },
    convertTimeObjInTimeString(timeObj) {
      if (timeObj == null) return "00:00:00";
      let timeString =
        this.padTo2Digits(timeObj.hours) +
        ":" +
        this.padTo2Digits(timeObj.minutes) +
        ":" +
        this.padTo2Digits(timeObj.seconds);
      return timeString;
    },
  },
  updated() {
    if (this.openDialog) {
      console.warn("+++ updated > Manage Marker Dialog");
      this.originalMarkerData = this.dataMarker.marker;
      console.log("+++ onUpdate this.originalMarkerData:", this.originalMarkerData);
      console.log("+++ onUpdate this.dataMarker:", this.dataMarker);
      this.currentTime = 0;
      this.resetTimeValues();
      this.initTimeValues();
      this.initMetadataDetails();
    }
  },
  beforeUnmount() {
    if (this.$refs.plyrPlayer_Marker) {
      this.$refs.plyrPlayer_Marker.player.destroy();
    }
  },
  mounted() {
    console.warn("+++ mounted > Manage Marker Dialog");
  },
};
</script>

<!-- Styles -->
<style src="@vueform/slider/themes/default.css"></style>
