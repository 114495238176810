<template>
  <main>
    <div v-if="isVisible" class="container content-container">
      <div class="align-items-center d-flex justify-content-center container p-0 m-0">
        <div class="header-text">
          <h1>Reset Password</h1>
        </div>
      </div>
      <div class="align-items-center d-flex justify-content-center row mx-1">
        <div class="login-wrap col-md-7 col-lg-6">
          <form novalidate class="m-3" @submit.prevent="resetPassword()">
            <div
              v-if="isPasswordSet"
              class="alert"
              style="color: #0b4974; background-color: #149efd; border-color: #68b3e6"
              role="alert"
            >
              The new password has been set correctly.
            </div>
            <!-- New Password -->
            <div v-if="!isPasswordSet" class="container p-0 my-5">
              <div class="container p-0">
                <div class="form-label my-2">NEW PASSWORD</div>
                <!-- Field -->
                <div
                  class="align-items-center"
                  :class="{
                    error: vPassword$.formChangePassword.newpassword.$errors.length,
                  }"
                >
                  <div class="input-group">
                    <input
                      id="newpassword"
                      ref="newpassword"
                      tabindex="5"
                      v-model.trim="vPassword$.formChangePassword.newpassword.$model"
                      type="password"
                      class="form-control"
                      placeholder="New password"
                      required
                      spellcheck="false"
                      @click.right.prevent
                      autocomplete="new-password"
                      autocapitalize="off"
                      @keydown.space.prevent
                      @change="
                        vPassword$.formChangePassword.newpassword.$model = vPassword$.formChangePassword.newpassword.$model.replace(
                          /\s/g,
                          ''
                        )
                      "
                    />
                    <button
                      class="input-group-button"
                      alt="GENERATE PASSWORD"
                      title="GENERATE PASSWORD"
                      type="button"
                      @click.prevent="
                        vPassword$.formChangePassword.newpassword.$model = generatePassword()
                      "
                    >
                      <i class="zmdi zmdi-lock zmdi-hc-lg"></i>
                    </button>
                    <button
                      class="input-group-button"
                      :alt="eye_new_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                      :title="eye_new_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                      type="button"
                      @click.prevent="eye_new_psw = toggleVisibility('newpassword')"
                    >
                      <i
                        :class="
                          eye_new_psw
                            ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                            : 'zmdi zmdi-eye zmdi-hc-lg'
                        "
                      ></i>
                    </button>
                  </div>
                  <!-- Error Password -->
                  <div
                    class="input-errors"
                    v-for="(error, index) of vPassword$.formChangePassword.newpassword
                      .$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Confirm Password -->
            <div v-if="!isPasswordSet" class="container p-0 my-5">
              <div class="container p-0">
                <div class="form-label my-2">CONFIRM PASSWORD</div>
                <!-- Field -->
                <div
                  class="align-items-center"
                  :class="{
                    error:
                      vPassword$.formChangePassword.confirm_newpassword.$errors.length,
                  }"
                >
                  <div class="input-group">
                    <input
                      id="confirm_newpassword"
                      ref="confirm_newpassword"
                      tabindex="6"
                      v-model.trim="
                        vPassword$.formChangePassword.confirm_newpassword.$model
                      "
                      type="password"
                      class="form-control"
                      placeholder="Confirm password"
                      required
                      spellcheck="false"
                      @click.right.prevent
                      autocomplete="new-password"
                      autocapitalize="off"
                      @keydown.space.prevent
                      @change="
                        vPassword$.formChangePassword.confirm_newpassword.$model = vPassword$.formChangePassword.confirm_newpassword.$model.replace(
                          /\s/g,
                          ''
                        )
                      "
                    />
                    <button
                      class="input-group-button"
                      :alt="eye_cnf_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                      :title="eye_cnf_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                      type="button"
                      @click.prevent="
                        eye_cnf_psw = toggleVisibility('confirm_newpassword')
                      "
                    >
                      <i
                        :class="
                          eye_cnf_psw
                            ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                            : 'zmdi zmdi-eye zmdi-hc-lg'
                        "
                      ></i>
                    </button>
                  </div>
                  <!-- Error Password -->
                  <div
                    class="input-errors"
                    v-for="(error, index) of vPassword$.formChangePassword
                      .confirm_newpassword.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-12">
                <!-- Login Button -->
                <base-icon-button
                  :typeButton="'button'"
                  :alignClass="'float-start'"
                  @click.prevent="$router.push({ name: 'view-login' })"
                  ><i class="zmdi zmdi-sign-in"></i>Login</base-icon-button
                >
                <!-- Reset password Button -->
                <base-icon-button
                  v-if="!isPasswordSet"
                  id="loginBtn"
                  ref="loginBtn"
                  :alignClass="'float-end'"
                  :typeButton="'submit'"
                  v-html="isLoading ? buttonCaption.wait : buttonCaption.send"
                ></base-icon-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import EventService from "@/services/EventService.js";
import BaseIconButton from "@/components/core/BaseIconButton.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, sameAs } from "@vuelidate/validators";

export default {
  components: { BaseIconButton },
  setup() {
    return { vUser$: useVuelidate(), vPassword$: useVuelidate() };
  },
  data() {
    return {
      eye_new_psw: false,
      eye_cnf_psw: false,
      formChangePassword: {
        newpassword: "",
        confirm_newpassword: "",
      },
      buttonCaption: {
        wait:
          '<span class="spinner-border spinner-border-sm" role="status"></span>&nbsp;Attendere',
        send: '<i class="zmdi zmdi-lock"></i>Reset Password',
      },
      isLoading: false,
      spinnerLoader: null,
      isUserLogged: false,
      isVisible: false,
      isPasswordSet: false,
      passwordToken: null,
    };
  },
  validations() {
    return {
      formChangePassword: {
        newpassword: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(8)
          ),
          passwordRequirements: helpers.withMessage(
            () =>
              "The password requires at least one uppercase character, one lowercase character and a number",
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/.test(value)
          ),
        },
        confirm_newpassword: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(8)
          ),
          passwordRequirements: helpers.withMessage(
            () =>
              "The password requires at least one uppercase character, one lowercase character and a number",
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/.test(value)
          ),
          sameAsPassword: helpers.withMessage(
            () => "Passwords don't match",
            sameAs(this.formChangePassword.newpassword)
          ),
        },
      },
    };
  },
  methods: {
    generatePassword() {
      let generatedPassword = "";
      let passwordLength = 12;
      const symbols = "!@#$%^&*(){}[]=<>/,.";
      for (let i = 0; i < passwordLength; i++) {
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 10) + 48); //number
        generatedPassword += symbols[Math.floor(Math.random() * symbols.length)]; //special characters
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 97); //alfabethic lower
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 65); //alfabethic upper
      }
      const finalPassword = generatedPassword.slice(0, passwordLength);
      this.vPassword$.formChangePassword.confirm_newpassword.$model = finalPassword;

      //enable visibility password fields
      this.eye_new_psw = true;
      this.showPasswordField("newpassword");
      this.eye_cnf_psw = true;
      this.showPasswordField("confirm_newpassword");

      return finalPassword;
    },
    toggleVisibility(field) {
      let inputField = document.getElementById(field);
      if (inputField.getAttribute("type") === "password") {
        inputField.setAttribute("type", "text");
        return true;
      } else {
        inputField.setAttribute("type", "password");
        return false;
      }
    },
    showPasswordField(field) {
      let inputField = document.getElementById(field);
      if (inputField.getAttribute("type") === "password") {
        inputField.setAttribute("type", "text");
      }
    },
    focusInput(field) {
      this.$refs[field].focus();
    },
    async resetPassword() {
      this.vPassword$.formChangePassword.$touch();
      if (this.vPassword$.formChangePassword.$invalid) {
        console.warn("++ validations reset password: fill in all fields");
        this.$root.addSnackBarMsg("RESET PASSWORD: Please fill in all fields", "warning");
        this.focusInput("newpassword");
        return false;
      }

      if (!this.passwordToken) {
        this.$root.addSnackBarMsg("Token parameter is missing", "error");
        this.focusInput("newpassword");
        return false;
      }

      //reset password
      this.showLoader(true);
      let objBody = {
        token: this.passwordToken,
        password: this.vPassword$.formChangePassword.newpassword.$model,
      };

      console.log("++ objBody", objBody);

      let apiResponseObj = await EventService.resetPassword(objBody);
      this.showLoader(false);

      //api error
      if (apiResponseObj.status != 200) {
        console.error(
          "Reset password >> error during updating password",
          apiResponseObj.status
        );
        this.$root.addSnackBarMsg(
          "RESET PASSWORD: An error has occurred, please try again",
          "error"
        );
        this.resetPasswordFields();
        return false;
      }

      console.log("Reset password >> 0 >> New password set successfully");
      this.$root.addSnackBarMsg("New password set successfully", "info");
      this.isPasswordSet = true;
    },
    resetPasswordFields() {
      this.formChangePassword.newpassword = "";
      this.formChangePassword.confirm_newpassword = "";
      this.$nextTick(() => {
        this.vPassword$.$reset();
      });
      this.focusInput("newpassword");
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async mounted() {
    //force logout
    this.$store.dispatch("clearToken").then(() => {});
    this.isVisible = true;
    setTimeout(() => {
      this.passwordToken = this.$route.query.d;
      this.focusInput("newpassword");
    }, 300);
  },
};
</script>
