<template>
  <main>
    <div v-if="isLoading" class="backdrop"></div>
    <div v-if="!isUserLogged" class="container content-container">
      <div class="align-items-center d-flex justify-content-center container p-0 m-0">
        <div class="header-text">
          <h1>Login</h1>
        </div>
      </div>
      <div class="align-items-center d-flex justify-content-center row mx-1">
        <div class="login-wrap col-md-7 col-lg-6">
          <form novalidate class="m-3" @submit.prevent="submitLogin()">
            <!-- UserName -->
            <div
              class="form-group mb-3"
              :class="{ error: v$.form.username.$errors.length }"
            >
              <label for="username" class="form-label">USERNAME</label>
              <input
                id="username"
                ref="username"
                v-model.trim="v$.form.username.$model"
                type="text"
                class="form-control"
                placeholder="Username"
                required
                spellcheck="false"
                @click.right.prevent
                @keydown.space.prevent
                autocomplete="on"
                autocapitalize="none"
              />
            </div>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.username.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
            <!-- Password -->
            <div
              class="form-group mb-3"
              :class="{ error: v$.form.password.$errors.length }"
            >
              <label for="password" class="form-label">PASSWORD</label>
              <input
                id="password"
                ref="password"
                v-model.trim="v$.form.password.$model"
                type="password"
                class="form-control"
                placeholder="Password"
                required
                spellcheck="false"
                @click.right.prevent
                @keydown.space.prevent
                autocomplete="off"
                autocapitalize="none"
              />
            </div>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.password.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
            <!-- Submit Login -->
            <div class="row mt-4">
              <div class="col-md-12">
                <!-- Login Button -->
                <base-icon-button
                  id="loginBtn"
                  ref="loginBtn"
                  :alignClass="'float-end'"
                  :typeButton="'submit'"
                  v-html="isLoading ? buttonCaption.wait : buttonCaption.login"
                  :disabled="v$.form.$invalid || isDisabled"
                ></base-icon-button>
              </div>
            </div>
            <hr class="sepGray mt-4 mb-4" />
            <!-- Forgot your password -->
            <div
              class="align-items-lg-center align-items-start d-flex flex-column flex-lg-row flex-md-row form-group justify-content-center justify-content-lg-between"
            >
              <!-- <div class="text-end">
                <router-link class="link-base" to="#" exact
                  ><span class="small">Forgot your password?</span></router-link
                >
              </div> -->
              <div class="text-end">
                <router-link class="link-base" :to="{ name: 'forgot-password' }" exact
                  ><span class="small">Forgot your password?</span></router-link
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods.js";
import BaseIconButton from "@/components/core/BaseIconButton.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";

export default {
  components: { BaseIconButton },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      buttonCaption: {
        wait:
          '<span class="spinner-border spinner-border-sm" role="status"></span>&nbsp;Attendere',
        login: '<i class="zmdi zmdi-sign-in"></i>Login',
      },
      isDisabled: false,
      isLoading: false,
      isUserLogged: true,
      save: true,
    };
  },
  validations() {
    return {
      form: {
        username: {
          required: helpers.withMessage("This field cannot be empty", required),
        },
        password: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(8)
          ),
        },
      },
    };
  },
  methods: {
    async submitLogin() {
      this.isDisabled = true;
      this.isLoading = true;
      this.v$.form.$touch();
      if (this.v$.form.$invalid) return false;
      // Call Login API
      let result = await EventService.loginUser(
        this.v$.form.username.$model,
        this.v$.form.password.$model
      );
      console.log("Login submitted:", result);

      await CommonMethods.sleep(2000);
      switch (result.status) {
        case 200:
          console.log("Login submit >> OK, set Token");
          await this.$store.dispatch("setToken", { result });
          await CommonMethods.sleep(1000);
          this.isLoading = false;
          this.isDisabled = false;
          this.$root.addSnackBarMsg("Hi " + result.data.name + ", welcome back!", "info");
          this.$router.push({ name: "view-videos-all" });
          break;
        case 401:
          console.error("Login submit >> wrong credentials");
          this.isLoading = false;
          this.isDisabled = false;
          this.$root.addSnackBarMsg("LOGIN: Wrong credentials", "error");
          break;
        default:
          console.error("Login submit >> Server error >>", result);
          this.isLoading = false;
          this.isDisabled = false;
          this.$root.addSnackBarMsg(
            "LOGIN: An error has occurred, please try again",
            "error"
          );
      }
    },
    focusInput() {
      this.$refs.username.focus();
    },
  },
  async beforeMount() {
    //if user is logged
    this.isUserLogged = await EventService.checkToken();
    if (this.isUserLogged) {
      this.$router.push({ name: "view-videos-all" });
    }
  },
  mounted() {
    if (!this.isUserLogged) {
      this.focusInput();
    }
  },
};
</script>
