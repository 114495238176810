<template>
  <div class="sidebar">
    <nav class="main-left-nav" role="navigation">
      <!-- All -->
      <ul class="unstyled nav-parent-item">
        <li>
          <router-link :to="{ name: 'view-pictures-new' }" exact
            >Add new image</router-link
          >
        </li>
        <hr role="separator" aria-orientation="horizontal" class="divider" />
        <li>
          <router-link :to="{ name: 'view-pictures', params: { id: 'all' } }" exact
            >All</router-link
          >
        </li>
      </ul>
      <hr
        v-if="categories[0] != undefined || tags[0] != undefined || areas[0] != undefined"
        aria-orientation="horizontal"
        role="separator"
        class="divider"
      />
      <!-- Categories -->
      <ul v-if="categories[0] != undefined" class="unstyled nav-parent-item">
        <li>
          <a
            href="#"
            class="collapsible"
            data-bs-toggle="collapse"
            data-bs-target="#categories-menu"
            >Categories</a
          >
          <!-- <a
            href="#"
            class="collapsible collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#categories-menu"
          >Categories</a> -->
          <div id="categories-menu" class="collapse show">
            <ul class="unstyled nav-child-item scroll-container">
              <li
                v-for="(dataElement, index) in categories"
                :key="index"
                :id="'category_' + dataElement.catId"
              >
                <router-link
                  :to="{ name: 'view-pictures', params: { id: dataElement.catId } }"
                  exact
                  >{{
                    dataElement.name != "" ? dataElement.name : "No name"
                  }}</router-link
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <!-- Tags -->
      <ul v-if="tags[0] != undefined" class="unstyled nav-parent-item">
        <li>
          <a
            href="#"
            class="collapsible collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#tags-menu"
            >Tags</a
          >
          <div id="tags-menu" class="collapse">
            <ul class="unstyled nav-child-item scroll-container">
              <li
                v-for="(dataElement, index) in tags"
                :key="index"
                :id="'tag_' + dataElement.tagId"
              >
                <router-link
                  :to="{ name: 'view-pictures', params: { id: dataElement.tagId } }"
                  exact
                  >{{
                    dataElement.name != "" ? dataElement.name : "No name"
                  }}</router-link
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <!-- Areas -->
      <ul v-if="areas[0] != undefined" class="unstyled nav-parent-item">
        <li>
          <a
            href="#"
            class="collapsible collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#areas-menu"
            >Areas</a
          >
          <div id="areas-menu" class="collapse">
            <ul class="unstyled nav-child-item scroll-container">
              <li
                v-for="(dataElement, index) in areas"
                :key="index"
                :id="'tag_' + dataElement.areaId"
              >
                <router-link
                  :to="{ name: 'view-pictures', params: { id: dataElement.areaId } }"
                  exact
                  >{{
                    dataElement.name != "" ? dataElement.name : "No name"
                  }}</router-link
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <hr role="separator" aria-orientation="horizontal" class="divider" />
      <!-- Sort -->
      <ul class="unstyled nav-parent-item">
        <li
          v-for="item in sortOrder"
          :key="item.text"
          @click.prevent="setOrder(item.value)"
        >
          <a
            href
            :class="{
              active: order == item.value,
            }"
            >{{ item.text }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";

export default {
  components: {},
  data() {
    return {
      categories: [],
      tags: [],
      areas: [],
      order: this.$store.getters.getVideosOrder,
    };
  },
  computed: {
    sortOrder() {
      return [
        {
          text: "Latest",
          value: "3",
        },
        {
          text: "Older",
          value: "2",
        },
        {
          text: "Alphabetically (A-Z)",
          value: "0",
        },
        {
          text: "Alphabetically (Z-A)",
          value: "1",
        },
      ];
    },
    isActive() {
      return this.$route.name == "view-pictures-all";
    },
  },
  methods: {
    async getCategories() {
      await EventService.getCategories(this.$store.getters.getToken.token);
      const categories = this.$store.getters.categories;
      //this.categories = this.getSemplifyCategories(categories);
      this.categories = CommonMethods.getSemplifyAttributes(categories, "catId");
      return this.categories;
    },
    async getTags() {
      await EventService.getTags(this.$store.getters.getToken.token);
      const tags = this.$store.getters.tags;
      this.tags = CommonMethods.getSemplifyAttributes(tags, "tagId");
      return this.tags;
    },
    setOrder(order = 3) {
      this.order = order;
      this.$store.dispatch("setVideosOrder", order);
      // if (this.$route.name != "library-all") {
      //   this.$router.push({ name: "library-all" });
      // }
    },
  },
  created() {
    console.log("VideosSidebar >> created >> load categories, tags and areas");
    // load categories, tags and areas
    this.getCategories();
    //this.getTags();
  },
};
</script>
