<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-11 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              IMAGES
              <span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="zmdi zmdi-collection-image"></i>
                </span>
                {{ titleObj.name }}&nbsp;&nbsp;&nbsp;
                <span style="font-size: smaller"
                  >({{ formatNumber(totImages) }} {{ getElementsLabel }})</span
                >
              </span>
            </h1>
          </div>
        </div>
        <div class="col-1 p-0">
          <!-- Search  -->
          <search-button @txtToSearch="searchText" @clearTxtToSearch="clearSearch" />
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Linear Loader -->
        <div v-if="isLoading" class="linear-loader">
          <span></span>
        </div>
        <!-- Table List -->
        <h6 class="mt-1" v-if="images && images.length == 0 && !isLoading">
          No data to display
        </h6>
        <div class="row m-0 padding-x-4px">
          <table
            v-if="images && images.length > 0"
            class="table-bordered table-responsive-md table-setup"
          >
            <thead class="table-header-font">
              <tr class="td-center">
                <th width="115">THUMBNAIL</th>
                <th>TITLE</th>
                <th>CATEGORIES</th>
                <th width="170">
                  CREATED
                  <br />MODIFIED
                </th>
                <th width="130">ACTIONS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <!-- Records content -->
              <record-pictures-list
                v-for="image in images"
                :key="image.id"
                :image="image"
                @editImage="editImage(image)"
                @deleteImage="deleteImage(image)"
                @resizeImage="resizeImage(image)"
              ></record-pictures-list>
            </tbody>
          </table>
          <!-- Pagination -->
          <div
            class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
          >
            <div class="row" v-if="images && images.length > 0 && getTotPages > 1">
              <vue-pagination
                :total-pages="getTotPages"
                :total="totImages"
                :per-page="maxPerPage"
                :current-page="currentPage"
                :maxVisibleButtons="6"
                @pagechanged="onPageChange"
              ></vue-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import SearchButton from "@/components/core/SearchButton.vue";
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";
import RecordPicturesList from "@/components/core/RecordPicturesList.vue";
import VuePagination from "@/components/ui/VuePagination.vue";

export default {
  components: { SearchButton, RecordPicturesList, VuePagination },
  data() {
    return {
      titleObj: {},
      images: [],
      image: {},
      paginatedImages: [],
      isLoading: true,
      currentPage: 1,
      apiPage: 0,
      maxPerPage: 20,
      totImages: 0,
      showDialog: false,
      searching: false,
      textToSearch: null,
      theCategoryId: null,
      spinnerLoader: null,
    };
  },
  computed: {
    getTotPages() {
      let totPages = 0;
      const a = this.totImages / this.maxPerPage;
      const b = a - Math.floor(a);
      if (b == 0) {
        totPages = a;
      } else {
        totPages = Math.floor(a) + 1;
      }
      console.log("** pages:", totPages);
      return totPages;
    },
    showReadMore() {
      console.log(
        "this.image.length < this.totImages",
        this.images.length < this.totImages
      );
      console.log("this.image.length,this.totImages", this.images.length, this.totImages);
      return this.images.length < this.totImages;
    },
    sort() {
      return this.$store.getters.getVideosOrder;
    },
    getElementsLabel() {
      return this.totImages == 1 ? "element" : "elements";
    },
  },
  watch: {
    sort() {
      this.setSortOrder();
    },
  },
  methods: {
    ...mapMutations(["setSearchImage"]),
    searchText(textToSearch) {
      if (textToSearch == null) return false;
      this.searching = true;
      this.textToSearch = textToSearch;
      this.setSearchImage(textToSearch);
      this.resetVariables();
      this.getPaginatedImages();
    },
    clearSearch() {
      this.searching = false;
      this.titleObj = this.getTitle();
      this.resetVariables();
      this.getPaginatedImages();
    },
    onPageChange(page) {
      this.currentPage = page;
      this.apiPage = this.currentPage - 1;
      this.getPaginatedImages();
    },
    editImage(image) {
      this.$router.push({
        name: "view-edit-picture",
        params: {
          id: image.imageId,
        },
      });
    },
    resizeImage(image) {
      this.$router.push({
        name: "view-resize-picture",
        params: {
          id: image.imageId,
        },
      });
    },

    deleteImage(image) {
      console.log("*** delete image:", image.imageId);
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this image?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.callDeleteImage(image.imageId);
          }
        });
    },
    async callDeleteImage(imageId) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE IMAGE: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.showLoader(true);

      const apiResponseObj = await EventService.deleteImage(imageId, this.token);
      if (
        apiResponseObj.status === 200 &&
        !apiResponseObj.data.toLowerCase().includes("error")
      ) {
        this.showLoader(false);
        this.$root.addSnackBarMsg("Image deleted", "info");
        //update list images
        this.images = [];
        this.paginatedImages = [];
        this.isLoading = true;
        this.currentPage = 1;
        this.apiPage = 0;
        this.totImages = 0;
        this.getPaginatedImages();
      } else {
        this.showLoader(false);
        console.error("Delete image >> error during delete item", apiResponseObj.status);
        this.$root.addSnackBarMsg(
          "DELETE IMAGE: Error the image is used by one or more lessons",
          "error"
        );
      }
    },
    setSortOrder() {
      this.resetVariables();
      this.getPaginatedImages();
    },
    resetVariables() {
      this.images = [];
      this.image = {};
      this.paginatedImages = [];
      this.isLoading = true;
      this.currentPage = 1;
      this.apiPage = 0;
      this.totImages = 0;
    },
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
    getTitle() {
      const categoryId = this.$route.params.id;
      if (categoryId === "all") {
        this.theCategoryId = categoryId;
        return { name: "All" };
      } else {
        const categories = CommonMethods.getSemplifyAttributes(
          this.$store.getters.categories,
          "catId"
        );
        const tempElement = this.foundElementInArrayById("catId", categoryId, categories);
        if (tempElement != undefined) {
          return { name: tempElement.name };
        } else {
          console.log("** error, no category found");
          this.$root.addSnackBarMsg("GET IMAGES: No category found", "error");
          return { name: "* Category not found *" };
        }
      }
    },
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    viewOptions(page) {
      const categoryId = this.$route.params.id;
      this.theCategoryId = categoryId;
      const size = this.maxPerPage;
      const sort = this.sort;
      const options = {
        page: page,
        size: size,
        sort: sort,
        category: categoryId,
      };
      return options;
    },
    viewOptionsSearch(page) {
      const categoryId = this.$route.params.id;
      this.theCategoryId = categoryId;
      const size = this.maxPerPage;
      const sort = this.sort.toString();
      const options = {
        page: page,
        size: size,
        sort: sort,
        category: categoryId,
        textToSearch: this.textToSearch,
      };
      console.warn("** image viewOptionsSearch textToSearch", this.textToSearch);
      return options;
    },
    async getImages(options) {
      let images = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET IMAGES: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;
      const apiResponseObj = await EventService.getAllImagesConsole(options, this.token);
      console.log("List all images >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        if (apiResponseObj.data.totImages > 0) {
          this.totImages = apiResponseObj.data.totImages;
          if (this.totImages == 0) {
            images = [];
          } else {
            images = apiResponseObj.data.images;
          }
        }
      } else {
        this.totImages = 0;
        this.isLoading = false;
        console.error("List images >> error during loading data");
        this.$root.addSnackBarMsg("GET IMAGES: Error during loading data", "error");
      }
      return images;
    },
    async getImageSearch(options) {
      let images = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET IMAGES: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;
      const apiResponseObj = await EventService.getSearchedConsoleImages(
        options,
        this.token
      );
      console.log("List all images >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        this.titleObj = { name: this.textToSearch };
        if (apiResponseObj.data.totImages > 0) {
          this.totImages = apiResponseObj.data.totImages;
          if (this.totImages == 0) {
            images = [];
          } else {
            images = apiResponseObj.data.images;
          }
        }
      } else {
        this.totImages = 0;
        this.isLoading = false;
        console.error("List images >> error during loading data");
        this.$root.addSnackBarMsg("GET IMAGES: Error during loading data", "error");
      }
      return images;
    },
    async getPaginatedImages() {
      this.isLoading = true;
      //await CommonMethods.sleep(3000);
      let sendOptions = null;
      if (this.searching) {
        sendOptions = this.viewOptionsSearch(this.apiPage);
        this.paginatedImages = await this.getImageSearch(sendOptions);
      } else {
        sendOptions = this.viewOptions(this.apiPage);
        this.paginatedImages = await this.getImages(sendOptions);
      }
      if (this.paginatedImages.length != undefined) {
        //empty images array
        this.images = [];
        //push images
        this.images.push(...this.paginatedImages);
        return this.images;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    await EventService.getCategories(this.$store.getters.getToken.token);
    this.titleObj = this.getTitle();
    console.log("** titleObj", this.titleObj);
    if (this.titleObj != undefined) {
      this.getPaginatedImages();
    } else {
      this.$router.push({
        name: "page-not-found",
        params: { notFound: "404" },
      });
    }
  },
};
</script>
