<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              MONITORING
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-flash"></i> </span>Encoding Jobs
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Table List -->
        <div class="row m-0 padding-x-4px">
          <h6 class="mt-1" v-if="processList && processList.length == 0">
            There are no jobs to process
          </h6>
          <table
            v-if="processList && processList.length > 0"
            class="table-bordered table-responsive-md table-setup"
          >
            <thead class="table-header-font">
              <tr class="td-center">
                <th width="40%">FILE NAME</th>
                <th width="15%">CREATED</th>
                <th width="15%">STATUS</th>
                <th width="30%">PROGRESS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <tr
                v-for="(encodingProcess, index) in processList"
                :key="index"
                :id="'process_' + index"
              >
                <!-- FileName -->
                <td>{{ encodingProcess.fileName }}</td>
                <!-- Created on -->
                <td class="td-center">
                  <ul class="unstyled">
                    <li>
                      {{ convertUnixDate(encodingProcess.creationDateUnixTimeStamp) }}
                    </li>
                  </ul>
                </td>
                <!-- Status -->
                <td class="td-center">{{ encodingProcess.state }}</td>
                <!-- Progress -->
                <td class="td-center">
                  <div class="progress" style="background-color: #adadad">
                    <div
                      :id="'progress_' + index"
                      class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                      role="progressbar1"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="
                        'width:' +
                        uploadStatusPercentage(encodingProcess.progress, index) +
                        '%'
                      "
                    >
                      {{ uploadStatusPercentage(encodingProcess.progress, index) }}%
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as signalR from "@microsoft/signalr";
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";

export default {
  components: {},
  data() {
    return {
      // BASEURL_WEBSOCKET: "http://accessocasa.ddns.net:15002/comm/lamberti",
      BASEURL_WEBSOCKET: process.env.VUE_APP_BASEURL_WEBSOCKET,
      // BASEURL_ENCODE: "http://accessocasa.ddns.net:62036",
      BASEURL_ENCODE: process.env.VUE_APP_BASEURL_ENCODE,
      searchText: null,
      connection: null,
      isStarted: false,
      isStopped: false,
      progressConstruct: {
        jobId: "",
        fileName: "",
        creationDateUnixTimeStamp: 0,
        state: "",
        progress: 0,
      },
      processDemoArray: [
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 1,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 2,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "640x360",
          step: 5,
          progress: 4,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 11,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "640x360",
          step: 5,
          progress: 13,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "640x360",
          step: 5,
          progress: 14,
        },
        {
          jobId: "102",
          fileName: "102 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "encoding ended with error",
          step: 5,
          progress: 17,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 18,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 18,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 18,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 19,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 20,
        },
        {
          jobId: "101",
          fileName: "101 filename",
          creationDateUnixTimeStamp: 1662999918,
          state: "1280x720",
          step: 5,
          progress: 25,
        },
      ],
      processList: [],
    };
  },
  methods: {
    async init(userToken) {
      return await new Promise((resolve, reject) => {
        this.connection = new signalR.HubConnectionBuilder()
          .withUrl(
            this.BASEURL_WEBSOCKET,
            {
              skipNegotiation: true,
              transport: signalR.HttpTransportType.WebSockets,
            },
            {
              accessTokenFactory: () => userToken,
            }
          )
          .withAutomaticReconnect()
          .build();
        this.connection
          .start()
          .then(() => {
            console.log("++ socket: signalr -> started");
            this.isStarted = true;
            //this.testSocket(this.$store.getters.getToken.token);
            if (this.isStopped) {
              this.stop();
              resolve(false);
            }
            //*** START PER TEST, DA LEVARE ***/
            //this.simulateCodeProcess();
            //*** END PER TEST, DA LEVARE ***/

            //receiving progress encoding
            this.connection.on("progressEncoder", (message) => {
              console.log("++ progressEncoder reciving:", message);
              this.progressEncoding(message);
            });

            //receiving encoding Speech to text
            this.connection.on("transcribeProgress", (message) => {
              console.log("++ transcribeProgress reciving:", message);
              this.transcribeEncoding(message);
            });
          })
          .catch((err) => {
            console.error(err.toString());
            reject(err);
          });
      });
    },
    stop() {
      if (this.isStarted) {
        this.connection.stop();
        this.isStarted = false;
        this.isStopped = false;
      } else {
        this.isStopped = true;
      }
    },
    uploadStatusPercentage(percentage, index) {
      let pBarStyle = document.getElementById("progress_" + index); //progress basr
      let pRocessStyle = document.getElementById("process_" + index); //tr row process
      if (this.processList[index].state == "encoding ended with error") {
        this.processList[index].state = "Encoding ended with error";
        if (pBarStyle) {
          pBarStyle.classList.remove(
            "progress-bar-striped",
            "progress-bar-animated",
            "bg-success"
          );
          pBarStyle.classList.add("bg-danger");
        }
        if (pRocessStyle) {
          pRocessStyle.classList.add("danger");
        }
      }

      let finalPercentage = percentage * this.processList[index].step;

      if (finalPercentage >= 100) {
        finalPercentage = 100;
        this.processList[index].state = "Completed";
        if (pBarStyle) {
          pBarStyle.classList.remove("progress-bar-striped", "progress-bar-animated");
        }
        if (pRocessStyle) {
          pRocessStyle.classList.add("success");
        }
      }
      return finalPercentage;
    },
    progressEncoding(message) {
      // let process = undefined;
      let element = {};
      let findJobId = [];
      if (message.jobId != "") {
        if (this.processList.length > 0) {
          //search all jobs in the processList and push new array of finded jobs
          for (let i = 0; i < this.processList.length; i++) {
            if (message.jobId == this.processList[i].jobId) {
              findJobId.push({
                jobId: this.processList[i].jobId,
                step: this.processList[i].step,
                index: i,
              });
            }
          }

          // console.warn("findJobId:", findJobId);
          // console.warn("message.jobId:", message.jobId);
          // console.warn("message.step:", message.step);

          if (findJobId.length > 0) {
            //jobs exist
            element = findJobId.find((el) => el.step == message.step);
            // if (Object.keys(element).length > 0) {
            if (element != undefined) {
              this.processList[element.index] = message;
            } else {
              this.processList.push(message);
            }
          } else {
            //new job
            this.processList.push(message);
          }
        } else {
          //new jobId
          this.processList.push(message);
        }
      }
      // console.log(this.processList);
      return this.processList;
    },
    transcribeEncoding(message) {
      let element = {};
      let findJobId = [];
      if (message.jobId != "") {
        if (this.processList.length > 0) {
          //search all jobs in the processList and push new array of finded jobs
          for (let i = 0; i < this.processList.length; i++) {
            if (message.jobId == this.processList[i].jobId) {
              findJobId.push({
                jobId: this.processList[i].jobId,
                step: this.processList[i].step,
                index: i,
              });
            }
          }
          if (findJobId.length > 0) {
            //jobs exist
            element = findJobId.find((el) => el.step == message.step);
            // if (Object.keys(element).length > 0) {
            if (element != undefined) {
              this.processList[element.index] = message;
            } else {
              this.processList.push(message);
            }
          } else {
            //new job
            this.processList.push(message);
          }
        } else {
          //new jobId
          this.processList.push(message);
        }
      }
      // console.log(this.processList);
      return this.processList;
    },
    convertUnixDate(date) {
      return CommonMethods.convertUnixTimeStampMoment(date);
    },
    async testSocket(token) {
      console.log("++ start testSocket");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(this.BASEURL_ENCODE + "/api/Encoder/testsocket", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    async simulateCodeProcess() {
      for (let i = 0; i < this.processDemoArray.length; i++) {
        this.progressEncoding(this.processDemoArray[i]);
        await CommonMethods.sleep(2000);
      }
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
  },
  async mounted() {
    await this.init(this.$store.getters.getToken.token);
  },
};
</script>
