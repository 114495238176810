<template>
  <tr>
    <!-- Thumbnail -->
    <td class="td-center">
      <img :src="getThumbnail()" width="100" />
    </td>
    <!-- Title -->
    <td>{{ getTitle() }}</td>
    <!-- Categories -->
    <td class="td-center">
      <ul class="list-tags">
        <li v-for="(category, index) in getCategories()" :key="index">
          <span
            v-if="category != null"
            class="badge-colored gray1 truncate-15-ch"
            :title="category"
            :alt="category"
            >{{ category }}</span
          >
        </li>
      </ul>
    </td>
    <!-- Duration -->
    <td class="td-center">
      <span
        class="badge-colored"
        :class="getDuration() != '00:00:00' ? 'green' : 'red'"
        >{{ getDuration() }}</span
      >
    </td>
    <!-- Language -->
    <td class="td-center">
      <ul class="list-tags">
        <li v-for="(language, index) in getLanguages()" :key="index">
          <span
            v-if="language != null"
            class="badge-colored gray2"
            :title="language"
            :alt="language"
            >{{ language }}</span
          >
        </li>
      </ul>
    </td>
    <!-- Created on & Published On -->
    <td class="td-center">
      <ul class="unstyled">
        <li>{{ convertUnixDate(video.creationDateUnixTimeStamp) }}</li>
        <li>{{ convertUnixDate(video.modificationDateUnixTimeStamp) }}</li>
      </ul>
    </td>
    <!-- Actions -->
    <td class="td-actions td-center">
      <button alt="EDIT" title="EDIT" @click="$emit('editVideo')">
        <i class="zmdi zmdi-edit zmdi-hc-lg"></i>
      </button>
      <button alt="DELETE" title="DELETE" @click="$emit('deleteVideo')">
        <i class="zmdi zmdi-delete zmdi-hc-lg"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import CommonMethods from "@/services/CommonMethods";
// import CommonMethods from "@/services/CommonMethods.js";

const METADATA_DEFAULT_LANG = process.env.VUE_APP_DEFAULT_METADATA_LANG;

export default {
  emits: ["editVideo", "deleteVideo"],
  props: {
    video: { type: Object, required: true },
  },
  components: {},
  data() {
    return {
      placeholder: require("@/assets/images/video-placeholder.png"),
    };
  },
  computed: {},
  methods: {
    formatDuration(duration) {
      let milliseconds = duration * 1000;
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      seconds = seconds % 60;
      minutes = minutes % 60;
      hours = hours % 24;
      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(
        minutes
      )}:${this.padTo2Digits(seconds)}`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    getDuration() {
      return this.formatDuration(this.video.duration);
    },
    getThumbnail() {
      const node_thumbnails = this.video.thumbnails;
      let thumbnail = this.placeholder;
      if (Object.keys(node_thumbnails).length > 0) {
        node_thumbnails.forEach((element) => {
          if (element.isDefault == true) {
            thumbnail = element.uri;
          }
        });
      }
      return thumbnail;
    },
    getTitle() {
      const node_metadata = this.video.metadata;
      let title = "";
      if (Object.keys(node_metadata).length > 0) {
        node_metadata.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            title = element.metaDataDetails.title;
          }
        });
      }
      return title;
    },
    getAuthor() {
      const node_metadata = this.video.metadata;
      let author = "";
      if (Object.keys(node_metadata).length > 0) {
        node_metadata.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            author = element.metaDataDetails.author;
          }
        });
      }
      return author;
    },
    getCategories() {
      const node_categories = this.video.categories;
      let categories = [];
      if (Object.keys(node_categories).length > 0) {
        node_categories.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            categories.push(element.name);
          }
        });
      }
      return categories;
    },
    getTags() {
      const node_tags = this.video.tags;
      let tags = [];
      if (Object.keys(node_tags).length > 0) {
        node_tags.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            tags.push(element.name);
          }
        });
      }
      return tags;
    },
    getAreas() {
      const node_areas = this.video.areas;
      let areas = [];
      if (Object.keys(node_areas).length > 0) {
        node_areas.forEach((element) => {
          if (element.lang == METADATA_DEFAULT_LANG) {
            areas.push(element.name);
          }
        });
      }
      return areas;
    },
    getLanguages() {
      const node_audioTracks = this.video.audioTracks;
      let languages = [];
      if (Object.keys(node_audioTracks).length > 0) {
        languages = node_audioTracks;
      }
      return languages;
    },
    convertUnixDate(date) {
      return CommonMethods.convertUnixTimeStampMoment(date);
    },
  },
};
</script>
