<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-12 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <!-- Title section -->
            <h1>
              USER
              <span class="text-white small">
                <span class="mx-3"> <i class="zmdi zmdi-account"></i> </span>New
              </span>
            </h1>
          </div>
        </div>
      </div>
      <!-- Linear Loader -->
      <div v-if="isLoading" class="linear-loader">
        <span></span>
      </div>
      <!-- Content -->
      <div v-if="isVisible" class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Title -->
        <div class="row m-0 padding-x-4px">
          <div class="col-md-12 p-0 mt-4">
            <h4 class="color-mainblue m-0 p-0">
              {{ formUserInfo.name }} {{ formUserInfo.surname }}
            </h4>
          </div>
          <hr class="sepGray" />
        </div>
        <!-- Form 1 -->
        <div class="row m-0">
          <form novalidate autocomplete="off">
            <!-- Name & Surname -->
            <div class="row">
              <div class="col-lg-6">
                <!-- Name -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">NAME</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{ error: vUser$.formUserInfo.name.$errors.length }"
                    >
                      <input
                        tabindex="1"
                        id="name"
                        ref="name"
                        v-model.trim="vUser$.formUserInfo.name.$model"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        required
                        spellcheck="false"
                        @click.right.prevent
                        autocomplete="off"
                        autocapitalize="on"
                        @input="isChangesSaved[1] = false"
                        maxlength="30"
                      />
                      <!-- Error Name -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vUser$.formUserInfo.name.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- Surname -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">SURNAME</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{ error: vUser$.formUserInfo.surname.$errors.length }"
                    >
                      <input
                        tabindex="2"
                        id="surname"
                        ref="surname"
                        v-model.trim="vUser$.formUserInfo.surname.$model"
                        type="text"
                        class="form-control"
                        placeholder="Surname"
                        required
                        spellcheck="false"
                        @click.right.prevent
                        autocomplete="off"
                        autocapitalize="on"
                        @input="isChangesSaved[1] = false"
                        maxlength="30"
                      />
                      <!-- Error Surname -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vUser$.formUserInfo.surname.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Email & Username -->
            <div class="row">
              <div class="col-lg-6">
                <!-- UserName -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">USERNAME</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{ error: vUser$.formUserInfo.username.$errors.length }"
                    >
                      <input
                        tabindex="4"
                        id="username"
                        ref="username"
                        v-model.trim="vUser$.formUserInfo.username.$model"
                        type="email"
                        class="form-control"
                        placeholder="Username must match the user's email"
                        required
                        spellcheck="false"
                        autocomplete="new-password"
                        @click.right.prevent
                        @keydown.space.prevent
                        @change="
                          vUser$.formUserInfo.username.$model = vUser$.formUserInfo.username.$model.replace(
                            /\s/g,
                            ''
                          )
                        "
                        autocapitalize="none"
                        @input="isChangesSaved[1] = false"
                        maxlength="100"
                      />
                      <!-- Error UserName -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vUser$.formUserInfo.username.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- E-mail -->
                <div v-show="false" class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">EMAIL</div>
                    </div>
                    <!-- Field -->
                    <div class="align-items-center col-lg-9 p-0">
                      <div class="form-control disabled">
                        {{ vUser$.formUserInfo.username.$model }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- Form 2 -->
        <div class="row m-0">
          <form novalidate autocomplete="off">
            <!-- Password & Confirm Password -->
            <div class="row">
              <div class="col-lg-6">
                <!-- Password -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">PASSWORD</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{
                        error: vPassword$.formChangePassword.newpassword.$errors.length,
                      }"
                    >
                      <div class="input-group">
                        <input
                          tabindex="4"
                          id="newpassword"
                          ref="newpassword"
                          v-model.trim="vPassword$.formChangePassword.newpassword.$model"
                          type="password"
                          class="form-control"
                          placeholder="Password"
                          required
                          spellcheck="false"
                          @click.right.prevent
                          autocomplete="new-password"
                          autocapitalize="off"
                          @keydown.space.prevent
                          @change="
                            vPassword$.formChangePassword.newpassword.$model = vPassword$.formChangePassword.newpassword.$model.replace(
                              /\s/g,
                              ''
                            )
                          "
                          @input="isChangesSaved[1] = false"
                        /><button
                          class="input-group-button"
                          alt="GENERATE PASSWORD"
                          title="GENERATE PASSWORD"
                          @click.prevent="
                            vPassword$.formChangePassword.newpassword.$model = generatePassword()
                          "
                        >
                          <i class="zmdi zmdi-lock zmdi-hc-lg"></i>
                        </button>
                        <button
                          class="input-group-button"
                          :alt="eye_new_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          :title="eye_new_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          @click.prevent="eye_new_psw = toggleVisibility('newpassword')"
                        >
                          <i
                            :class="
                              eye_new_psw
                                ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                                : 'zmdi zmdi-eye zmdi-hc-lg'
                            "
                          ></i>
                        </button>
                      </div>
                      <!-- Error Password -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vPassword$.formChangePassword.newpassword
                          .$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- Confirm Password -->
                <div class="container m-0 my-2">
                  <div class="align-items-start d-flex row">
                    <div class="col-lg-3 pe-0">
                      <div class="form-label my-2">CONFIRM PASSWORD</div>
                    </div>
                    <!-- Field -->
                    <div
                      class="align-items-center col-lg-9 p-0"
                      :class="{
                        error:
                          vPassword$.formChangePassword.confirm_newpassword.$errors
                            .length,
                      }"
                    >
                      <div class="input-group">
                        <input
                          tabindex="5"
                          id="confirm_newpassword"
                          ref="confirm_newpassword"
                          v-model.trim="
                            vPassword$.formChangePassword.confirm_newpassword.$model
                          "
                          type="password"
                          class="form-control"
                          placeholder="Confirm Password"
                          required
                          spellcheck="false"
                          @click.right.prevent
                          autocomplete="new-password"
                          autocapitalize="off"
                          @keydown.space.prevent
                          @change="
                            vPassword$.formChangePassword.confirm_newpassword.$model = vPassword$.formChangePassword.confirm_newpassword.$model.replace(
                              /\s/g,
                              ''
                            )
                          "
                          @input="isChangesSaved[1] = false"
                        /><button
                          class="input-group-button"
                          :alt="eye_cnf_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          :title="eye_cnf_psw ? 'HIDE PASSWORD' : 'SHOW PASSWORD'"
                          @click.prevent="
                            eye_cnf_psw = toggleVisibility('confirm_newpassword')
                          "
                        >
                          <i
                            :class="
                              eye_cnf_psw
                                ? 'zmdi zmdi-eye-off zmdi-hc-lg'
                                : 'zmdi zmdi-eye zmdi-hc-lg'
                            "
                          ></i>
                        </button>
                      </div>
                      <!-- Error Confirm Password -->
                      <div
                        class="input-errors"
                        v-for="(error, index) of vPassword$.formChangePassword
                          .confirm_newpassword.$errors"
                        :key="index"
                      >
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- Checkbox -->
        <div class="row m-0">
          <!-- Is Admin -->
          <div class="col-lg-6 d-flex align-items-center">
            <div class="container m-0 my-2">
              <div class="align-items-center d-flex row">
                <div class="col-lg-3 pe-0">
                  <div class="form-label my-2">IS ADMIN</div>
                </div>
                <div
                  class="form-check form-switch align-items-center col-lg-9 d-flex flex-row"
                >
                  <input
                    class="form-check-input"
                    style="margin-top: 0"
                    type="checkbox"
                    v-model="model_checkbox_isadmin"
                    @input="handleCheckboxIsAdmin($event)"
                  />
                  <ul class="list-tags ms-2">
                    <li>
                      <span
                        v-if="model_checkbox_isadmin"
                        class="badge-colored blue"
                        :title="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        :alt="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        >{{ getUserTypeLabel(model_checkbox_isadmin) }}</span
                      >
                      <span
                        v-if="!model_checkbox_isadmin"
                        class="badge-colored fucsia"
                        :title="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        :alt="`${getUserTypeLabel(model_checkbox_isadmin)}`"
                        >{{ getUserTypeLabel(model_checkbox_isadmin) }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Send Email Reminder -->
          <div class="col-lg-6 d-flex align-items-center">
            <div class="container m-0 my-2">
              <div class="align-items-center d-flex row">
                <div class="col-lg-3 pe-0">
                  <div class="form-label my-2">SEND EMAIL REMINDER</div>
                </div>
                <div
                  class="form-check form-switch align-items-center col-lg-9 d-flex flex-row"
                >
                  <input
                    class="form-check-input"
                    style="margin-top: 0"
                    type="checkbox"
                    v-model="model_checkbox_sendemailreminder"
                    checked
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="sepGray" />
        <!-- Actions -->
        <div class="container-fluid my-3">
          <!-- Add user -->
          <button
            @click="addNewUser()"
            type="button"
            class="btn button-outline-1 float-end ms-4"
            :class="notChangesSaved ? 'notSaved' : ''"
          >
            <span> <i class="zmdi zmdi-floppy"></i>Add User</span>
          </button>
          <!-- Reset Fields -->
          <button
            @click="resetFields()"
            type="button"
            class="btn button-outline-1 float-end"
          >
            <span> <i class="zmdi zmdi-rotate-left"></i>Reset Fields</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventService from "@/services/EventService.js";
// import CommonMethods from "@/services/CommonMethods";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers, minLength, sameAs } from "@vuelidate/validators";

const CLIENT_ID = "54O3cD4PhljVsEV";

export default {
  setup() {
    return { vUser$: useVuelidate(), vPassword$: useVuelidate() };
  },
  data() {
    return {
      eye_new_psw: false,
      eye_cnf_psw: false,
      isUserLogged: false,
      isLoading: false,
      isVisible: true,
      isChangesSaved: [],
      formUserInfo: {
        name: "",
        surname: "",
        username: "",
      },
      formChangePassword: {
        newpassword: "",
        confirm_newpassword: "",
      },
      model_checkbox_isadmin: false,
      model_checkbox_sendemailreminder: true,
      userTypes: [
        { type: "users", label: "Users", tag: "USER", isAdmin: false, isAdminStr: "N" },
        { type: "admins", label: "Admins", tag: "ADMIN", isAdmin: true, isAdminStr: "Y" },
      ],
      spinnerLoader: null,
    };
  },
  validations() {
    return {
      formUserInfo: {
        name: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(2)
          ),
        },
        surname: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(2)
          ),
        },
        username: {
          required: helpers.withMessage("This field cannot be empty", required),
          username: helpers.withMessage("It's not a valid email", email),
        },
      },
      formChangePassword: {
        newpassword: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(8)
          ),
          passwordRequirements: helpers.withMessage(
            () =>
              "The password requires at least one uppercase character, one lowercase character and a number",
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/.test(value)
          ),
        },
        confirm_newpassword: {
          required: helpers.withMessage("This field cannot be empty", required),
          minLength: helpers.withMessage(
            ({ $params }) => `The minimum length is ${$params.min} characters`,
            minLength(8)
          ),
          passwordRequirements: helpers.withMessage(
            () =>
              "The password requires at least one uppercase character, one lowercase character and a number",
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/.test(value)
          ),
          sameAsPassword: helpers.withMessage(
            () => "Passwords don't match",
            sameAs(this.formChangePassword.newpassword)
          ),
        },
      },
    };
  },
  computed: {
    notChangesSaved() {
      if (!this.isChangesSaved[1]) {
        return true;
      }
      return false;
    },
  },
  methods: {
    initChangesSaved() {
      this.isChangesSaved[1] = true;
    },
    focusInput() {
      this.$refs.name.focus();
    },
    focusUsername() {
      this.$refs.username.focus();
    },
    toggleVisibility(field) {
      let inputField = document.getElementById(field);
      if (inputField.getAttribute("type") === "password") {
        inputField.setAttribute("type", "text");
        return true;
      } else {
        inputField.setAttribute("type", "password");
        return false;
      }
    },
    showPasswordField(field) {
      let inputField = document.getElementById(field);
      if (inputField.getAttribute("type") === "password") {
        inputField.setAttribute("type", "text");
      }
    },
    handleCheckboxIsAdmin(event) {
      if (event.target.checked) {
        this.model_checkbox_isadmin = true;
      } else {
        this.model_checkbox_isadmin = false;
      }
      this.isChangesSaved[1] = false;
    },
    getUserTypeLabel(isAdmin) {
      const tempElement = this.foundElementInArrayById(
        "isAdmin",
        isAdmin,
        this.userTypes
      );
      if (tempElement != undefined) {
        return tempElement.tag;
      }
      return "-";
    },
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    generatePassword() {
      let generatedPassword = "";
      let passwordLength = 12;
      const symbols = "!@#$%^&*(){}[]=<>/,.";
      for (let i = 0; i < passwordLength; i++) {
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 10) + 48); //number
        generatedPassword += symbols[Math.floor(Math.random() * symbols.length)]; //special characters
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 97); //alfabethic lower
        generatedPassword += String.fromCharCode(Math.floor(Math.random() * 26) + 65); //alfabethic upper
      }
      const finalPassword = generatedPassword.slice(0, passwordLength);
      this.vPassword$.formChangePassword.confirm_newpassword.$model = finalPassword;

      //enable visibility password fields
      this.eye_new_psw = true;
      this.showPasswordField("newpassword");
      this.eye_cnf_psw = true;
      this.showPasswordField("confirm_newpassword");

      this.isChangesSaved[1] = false;
      return finalPassword;
    },
    resetFields() {
      this.formUserInfo.name = "";
      this.formUserInfo.surname = "";
      this.formUserInfo.username = "";
      this.formChangePassword.newpassword = "";
      this.formChangePassword.confirm_newpassword = "";
      this.model_checkbox_isadmin = false;
      this.$nextTick(() => {
        this.vUser$.$reset();
      });
      this.$nextTick(() => {
        this.vPassword$.$reset();
      });
      this.initChangesSaved();
      this.focusInput();
    },
    async addNewUser() {
      this.vUser$.$touch();
      this.vPassword$.$touch();
      if (this.vUser$.$invalid && this.vPassword$.$invalid) {
        console.warn("++ validations add new user: fill in all fields");
        this.$root.addSnackBarMsg("ADD NEW USER: Please fill in all fields", "warning");
        this.focusInput();
        return false;
      }

      console.log("++ validations add new user ok");

      //api add user
      this.showLoader(true);
      let bodyObj = {
        userName: this.formUserInfo.username,
        clientid: CLIENT_ID,
        password: this.formChangePassword.newpassword,
        metadata: {
          name: this.formUserInfo.name,
          surname: this.formUserInfo.surname,
          email: this.formUserInfo.username,
        },
        fullName: this.formUserInfo.name + " " + this.formUserInfo.surname,
        lang: "it",
        isAdmin: this.model_checkbox_isadmin,
      };
      let apiResponseObj = await EventService.addNewUser(
        bodyObj,
        this.$store.getters.getToken.token
      );
      this.showLoader(false);
      if (apiResponseObj.status != 200) {
        if (apiResponseObj.status == 400) {
          console.error("Add new user >> User already exist", apiResponseObj.status);
          this.$root.addSnackBarMsg("ADD NEW USER: Username already exist", "warning");
          this.focusUsername();
        } else {
          console.error("Add new user >> error during add user", apiResponseObj.status);
          this.$root.addSnackBarMsg(
            "ADD NEW USER: An error has occurred, please try again",
            "error"
          );
          this.focusInput();
        }

        this.initChangesSaved();
        return false;
      }

      console.log("++ User added");
      this.$root.addSnackBarMsg(
        `${this.formUserInfo.name} ${this.formUserInfo.surname} added as ${
          this.model_checkbox_isadmin ? "administrator" : "user"
        }`,
        "info"
      );

      //api send email reminder
      if (this.model_checkbox_sendemailreminder) {
        this.showLoader(true);
        bodyObj = {
          clientid: CLIENT_ID,
          email: this.formUserInfo.username,
          password: this.formChangePassword.newpassword,
        };
        apiResponseObj = await EventService.sendEmailRemainder(
          bodyObj,
          this.$store.getters.getToken.token
        );
        this.showLoader(false);

        if (apiResponseObj.status != 200) {
          console.error(
            "Send email reminder >> error during send email",
            apiResponseObj.status
          );
          this.$root.addSnackBarMsg(
            "SEND EMAIL REMINDER: An error has occurred, please try again",
            "error"
          );
          this.focusInput();
          this.initChangesSaved();
          return false;
        }

        console.log("++ Your email reminder has been sent");
        this.$root.addSnackBarMsg("Your email reminder has been sent", "info");
      }

      this.initChangesSaved();
      this.resetFields();
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async beforeMount() {
    //if user is not logged
    this.isUserLogged = await EventService.checkToken();
    if (!this.isUserLogged) {
      this.isLoading = false;
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    //init changes saved
    this.initChangesSaved();
  },
  mounted() {
    setTimeout(() => {
      if (this.isUserLogged) {
        this.focusInput();
      }
    }, 200);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChangesSaved.indexOf(false) > 0) {
      this.$swal
        .fire({
          title: "Data unsaved",
          html: "Do you really want to leave? You have unsaved changes!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      clearInterval(this.intervalEncodingSTTInProgress);
      next();
    }
  },
};
</script>
