import { createStore } from "vuex";
import Token from "./token";
import Data from "./data";

export default createStore({
  state: {
    videosOrder: 3,
    attributesOrder: 0,
    searchImage:null,
    searchVideo:null
  },
  getters: {
    getVideosOrder: state => state.videosOrder,
    getAttributesOrder: state => state.attributesOrder
  },
  mutations: {
    setSearchImage(state, payload) {
      state.searchImage = payload;
    },
    setSearchVideo(state, payload) {
      state.searchVideo = payload;
    },
    setVideosOrder(state, payload) {
      state.videosOrder = payload;
    },
    setAttributesOrder(state, payload) {
      state.attributesOrder = payload;
    }
  },
  actions: {
    setVideosOrder({ commit }, payload) {
      commit("setVideosOrder", payload);
    },
    setAttributesOrder({ commit }, payload) {
      commit("setAttributesOrder", payload);
    }
  },
  modules: {
    Token,
    Data
  }
});
