<template>
  <header class="sticky-top">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <a href="https://www.medialivesystem.com" target="_blank">
          <img :src="require('@/assets/images/logo-Medialive.png')" width="170" />
        </a>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
