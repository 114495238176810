<template>
  <div class="col-sm-12 main-col">
    <div class="row p-0">
      <div class="align-items-center d-flex flex-row justify-content-between m-0 p-0 row">
        <div class="col-11 p-0">
          <div
            class="align-items-center bg-black d-flex header-text justify-content-start p-0 w-100"
          >
            <h1>
              VIDEOS
              <span class="text-white small">
                <span class="mx-3 single-list">
                  <i class="zmdi zmdi-collection-video"></i>
                </span>
                {{ titleObj.name }}&nbsp;&nbsp;&nbsp;
                <span style="font-size: smaller"
                  >({{ formatNumber(totVideos) }} {{ getElementsLabel }})</span
                >
              </span>
            </h1>
          </div>
        </div>
        <div class="col-1 p-0">
          <!-- Search  -->
          <search-button @txtToSearch="searchText" @clearTxtToSearch="clearSearch" />
        </div>
      </div>
      <!-- Content -->
      <div class="container-fluid m-0 p-2 background-gray overflow-auto">
        <!-- Linear Loader -->
        <div v-if="isLoading" class="linear-loader">
          <span></span>
        </div>
        <!-- Table List -->
        <h6 class="mt-1" v-if="videos && videos.length == 0 && !isLoading">
          No data to display
        </h6>
        <div class="row m-0 padding-x-4px">
          <table
            v-if="videos && videos.length > 0"
            class="table-bordered table-responsive-md table-setup"
          >
            <thead class="table-header-font">
              <tr class="td-center">
                <th width="115">THUMBNAIL</th>
                <th>TITLE</th>
                <th>CATEGORIES</th>
                <th width="120">DURATION</th>
                <th width="80">LANGUAGE</th>
                <th width="170">
                  CREATED
                  <br />MODIFIED
                </th>
                <th width="80">ACTIONS</th>
              </tr>
            </thead>
            <tbody class="table-body-font td-vertical-center">
              <!-- Records content -->
              <record-video-list
                v-for="video in videos"
                :key="video.id"
                :video="video"
                @editVideo="editVideo(video)"
                @deleteVideo="deleteVideo(video)"
              ></record-video-list>
            </tbody>
          </table>
          <!-- Pagination -->
          <div
            class="align-items-center container-fluid d-flex justify-content-center my-4 p-0"
          >
            <div class="row" v-if="videos && videos.length > 0 && getTotPages > 1">
              <vue-pagination
                :total-pages="getTotPages"
                :total="totVideos"
                :per-page="maxPerPage"
                :current-page="currentPage"
                :maxVisibleButtons="6"
                @pagechanged="onPageChange"
              ></vue-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import SearchButton from "@/components/core/SearchButton.vue";
import EventService from "@/services/EventService.js";
import CommonMethods from "@/services/CommonMethods";
import RecordVideoList from "@/components/core/RecordVideosList.vue";
import VuePagination from "@/components/ui/VuePagination.vue";

export default {
  components: { SearchButton, RecordVideoList, VuePagination },
  data() {
    return {
      titleObj: {},
      videos: [],
      video: {},
      paginatedVideos: [],
      isLoading: true,
      currentPage: 1,
      apiPage: 0,
      maxPerPage: 20,
      totVideos: 0,
      showDialog: false,
      videoUrl: null,
      videoPoster: null,
      videoTitle: null,
      searching: false,
      textToSearch: null,
      theCategoryId: null,
      spinnerLoader: null,
    };
  },
  computed: {
    getTotPages() {
      let totPages = 0;
      const a = this.totVideos / this.maxPerPage;
      const b = a - Math.floor(a);
      if (b == 0) {
        totPages = a;
      } else {
        totPages = Math.floor(a) + 1;
      }
      console.log("** pages:", totPages);
      return totPages;
    },
    showReadMore() {
      console.log(
        "this.video.length < this.totVideos",
        this.videos.length < this.totVideos
      );
      console.log("this.video.length,this.totVideos", this.videos.length, this.totVideos);
      return this.videos.length < this.totVideos;
    },
    sort() {
      return this.$store.getters.getVideosOrder;
    },
    getElementsLabel() {
      return this.totVideos == 1 ? "element" : "elements";
    },
  },
  watch: {
    sort() {
      this.setSortOrder();
    },
  },
  methods: {
    ...mapMutations(["setSearchVideo"]),
    searchText(textToSearch) {
      if (textToSearch == null) return false;
      this.searching = true;
      this.textToSearch = textToSearch;
      // const videosSearch = JSON.stringify(textToSearch);
      // this.setSearchVideo(videosSearch);
      this.setSearchVideo(textToSearch);
      this.resetVariables();
      this.getPaginatedVideos();
    },
    clearSearch() {
      this.searching = false;
      this.titleObj = this.getTitle();
      this.resetVariables();
      this.getPaginatedVideos();
    },
    onPageChange(page) {
      this.currentPage = page;
      this.apiPage = this.currentPage - 1;
      this.getPaginatedVideos();
    },
    editVideo(video) {
      this.$router.push({
        name: "view-edit-video",
        params: {
          id: video.videoId,
        },
      });
    },
    deleteVideo(video) {
      console.log("*** delete video:", video.videoId);
      this.$swal
        .fire({
          title: "Delete",
          html:
            "Are you sure you want to delete this video?<br>You won't be able to revert this!",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: '<i class="fa fa-check"></i> Ok',
          cancelButtonText: '<i class="fa fa-xmark"></i> Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.callDeleteVideo(video.videoId);
          }
        });
    },
    async callDeleteVideo(videoId) {
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.$root.addSnackBarMsg(
          "DELETE VIDEO: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.showLoader(true);

      const apiResponseObj = await EventService.deleteVideo(videoId, this.token);
      if (
        apiResponseObj.status === 200 &&
        !apiResponseObj.data.toLowerCase().includes("error")
      ) {
        this.showLoader(false);
        this.$root.addSnackBarMsg("Video deleted", "info");
        //update list videos
        this.videos = [];
        this.paginatedVideos = [];
        this.isLoading = true;
        this.currentPage = 1;
        this.apiPage = 0;
        this.totVideos = 0;
        this.getPaginatedVideos();
      } else {
        this.showLoader(false);
        console.error("Delete video >> error during delete item", apiResponseObj.status);
        this.$root.addSnackBarMsg(
          "DELETE VIDEO: Error the video is used by one or more lessons",
          "error"
        );
      }
    },
    setSortOrder() {
      this.resetVariables();
      this.getPaginatedVideos();
    },
    resetVariables() {
      this.videos = [];
      this.video = {};
      this.paginatedVideos = [];
      this.isLoading = true;
      this.currentPage = 1;
      this.apiPage = 0;
      this.totVideos = 0;
      this.videoUrl = null;
      this.videoPoster = null;
      this.videoTitle = null;
    },
    formatNumber(num) {
      return CommonMethods.formatNumberIT(num).trim();
    },
    getTitle() {
      const categoryId = this.$route.params.id;
      if (categoryId === "all") {
        this.theCategoryId = categoryId;
        return { name: "All" };
      } else {
        const categories = CommonMethods.getSemplifyAttributes(
          this.$store.getters.categories,
          "catId"
        );
        const tempElement = this.foundElementInArrayById("catId", categoryId, categories);
        if (tempElement != undefined) {
          return { name: tempElement.name };
        } else {
          console.log("** error, no category found");
          this.$root.addSnackBarMsg("GET VIDEOS: No category found", "error");
          return { name: "* Category not found *" };
        }
      }
    },
    foundElementInArrayById(key, elementId, arr) {
      let foundElement = {};
      foundElement = arr.find((el) => el[key] === elementId);
      return foundElement;
    },
    viewOptions(page) {
      const categoryId = this.$route.params.id;
      this.theCategoryId = categoryId;
      const size = this.maxPerPage;
      const sort = this.sort;
      const options = {
        page: page,
        size: size,
        sort: sort,
        category: categoryId,
      };
      return options;
    },
    viewOptionsSearch(page) {
      const categoryId = this.$route.params.id;
      this.theCategoryId = categoryId;
      const size = this.maxPerPage;
      const sort = this.sort.toString();
      const options = {
        page: page,
        size: size,
        sort: sort,
        category: categoryId,
        textToSearch: this.textToSearch,
      };
      console.warn("** video viewOptionsSearch textToSearch", this.textToSearch);
      return options;
    },
    async getVideos(options) {
      let videos = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET VIDEOS: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;
      const apiResponseObj = await EventService.getAllVideosConsole(options, this.token);
      console.log("List all videos >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        if (apiResponseObj.data.totVideos > 0) {
          this.totVideos = apiResponseObj.data.totVideos;
          if (this.totVideos == 0) {
            videos = [];
          } else {
            videos = apiResponseObj.data.videos;
          }
        }
      } else {
        this.totVideos = 0;
        this.isLoading = false;
        console.error("List videos >> error during loading data");
        this.$root.addSnackBarMsg("GET VIDEOS: Error during loading data", "error");
      }
      return videos;
    },
    async getVideosSearch(options) {
      let videos = [];
      if (this.isLogged) {
        this.token = this.$store.getters.getToken.token;
      } else {
        this.isLoading = false;
        this.$root.addSnackBarMsg(
          "GET VIDEOS: An error has occurred, please try again",
          "error"
        );
        this.$store.dispatch("clearToken").then(() => {});
        this.$router.push({ name: "view-login" });
        return false;
      }
      this.isLoading = true;
      const apiResponseObj = await EventService.getSearchedConsoleVideos(
        options,
        this.token
      );
      console.log("List all videos >> data >>", apiResponseObj);
      if (apiResponseObj.status === 200) {
        this.isLoading = false;
        this.titleObj = { name: this.textToSearch };
        if (apiResponseObj.data.totVideos > 0) {
          this.totVideos = apiResponseObj.data.totVideos;
          if (this.totVideos == 0) {
            videos = [];
          } else {
            videos = apiResponseObj.data.videos;
          }
        }
      } else {
        this.totVideos = 0;
        this.isLoading = false;
        console.error("List videos >> error during loading data");
        this.$root.addSnackBarMsg("GET VIDEOS: Error during loading data", "error");
      }
      return videos;
    },
    async getPaginatedVideos() {
      this.isLoading = true;
      //await CommonMethods.sleep(3000);
      let sendOptions = null;
      if (this.searching) {
        sendOptions = this.viewOptionsSearch(this.apiPage);
        this.paginatedVideos = await this.getVideosSearch(sendOptions);
      } else {
        sendOptions = this.viewOptions(this.apiPage);
        this.paginatedVideos = await this.getVideos(sendOptions);
      }
      if (this.paginatedVideos.length != undefined) {
        //empty videos array
        this.videos = [];
        //push videos
        this.videos.push(...this.paginatedVideos);
        return this.videos;
      } else {
        this.isLoading = false;
        return false;
      }
    },
    isLogged() {
      return this.$store.getters.getUserIsLogged;
    },
    showLoader(isShow) {
      if (isShow) {
        this.spinnerLoader = this.$loading.show();
      } else {
        if (this.spinnerLoader) {
          this.spinnerLoader.hide();
        }
      }
    },
  },
  async beforeMount() {
    //if user is not logged
    const isUserLogged = await EventService.checkToken();
    if (!isUserLogged) {
      this.$root.addSnackBarMsg("Unauthorized access, please login!", "error");
      this.$store.dispatch("clearToken").then(() => {});
      this.$router.push({ name: "view-login" });
      return false;
    }
    await EventService.getCategories(this.$store.getters.getToken.token);
    this.titleObj = this.getTitle();
    console.log("** titleObj", this.titleObj);
    if (this.titleObj != undefined) {
      this.getPaginatedVideos();
    } else {
      this.$router.push({
        name: "page-not-found",
        params: { notFound: "404" },
      });
    }
  },
};
</script>
