import moment from "moment";
// import EventService from "@/services/EventService";

export default {
  state: {
    token: null,
    login: false,
    isLogged: false,
  },
  mutations: {
    isUserLogged(state) {
      state.isLogged = true;
    },
    async setToken(state, response) {
      console.log("** token.js, async setToken >> response:", response);
      let authName = "";
      if (response.result.data.name == undefined || response.result.data.name == null) {
        authName = "No name";
      } else {
        authName = response.result.data.name;
      }
      // Set token object
      const objTokenToSave = {
        name: authName,
        provider: response.result.data.provider,
        refreshToken: response.result.data.refreshToken,
        token: response.result.data.token,
        userId: response.result.data.userId,
        providerUserId: response.result.data.providerUserId,
        username: response.result.data.username,
        validUntil: response.result.data.validUntil,
        isAdmin: response.result.data.isAdmin,
      };

      // ** START TO REMARK, ONLY FOR DEBUG ** //
      // let unixTimeStamp = Math.round(new Date().getTime() / 1000) + 10;
      // const objTokenToSave = {
      //   name: authName,
      //   provider: response.result.data.provider,
      //   refreshToken: response.result.data.refreshToken,
      //   token: response.result.data.token,
      //   userId: response.result.data.userId,
      //   username: response.result.data.username,
      //   validUntil: unixTimeStamp
      // };
      // ** END TO REMARK, ONLY FOR DEBUG ** //

      state.token = objTokenToSave;
      state.login = true;
      state.isLogged = true;
      localStorage.setItem("AuthSessionData", JSON.stringify(objTokenToSave));
      console.log("** token.js, >> setToken", objTokenToSave);
    },
    async updateTokenName(state, dataToUpdate) {
      state.token.name = dataToUpdate;
      const objTokenToSave = {
        name: state.token.name,
        provider: state.token.provider,
        refreshToken: state.token.refreshToken,
        token: state.token.token,
        userId: state.token.userId,
        providerUserId: state.token.providerUserId,
        username: state.token.username,
        validUntil: state.token.validUntil,
        isAdmin: state.token.isAdmin,
      };
      localStorage.setItem("AuthSessionData", JSON.stringify(objTokenToSave));
    },
    async loadLocalToken(state) {
      let token = localStorage.getItem("AuthSessionData");
      if (token) {
        try {
          console.log("** token.js, >> load token from localStorage");
          state.token = JSON.parse(token);
          state.isLogged = true;
        } catch (e) {
          console.error("** token.js, error during loading from localStorage >>", e);
          state.token = null;
          state.isLogged = false;
        }
      } else {
        console.log(
          "** token.js, >> load token from localStorage, localStorage is empty"
        );
        state.token = null;
        state.isLogged = false;
      }
    },
  },
  actions: {
    isUserLogged({ commit }) {
      commit("isUserLogged");
    },
    setToken({ commit }, payload) {
      commit("setToken", payload);
    },
    updateTokenName({ commit }, payload) {
      commit("updateTokenName", payload);
    },
    loadLocalToken({ commit }) {
      commit("loadLocalToken");
    },
    login({ commit }, payload) {
      commit("login", payload);
    },
    clearToken(state) {
      state.token = null;
      state.isLogged = false;
      state.login = false;
      localStorage.removeItem("AuthSessionData");
      console.log(
        "** token.js, clearToken:" + "state.token:",
        state.token + " state.isLogged:",
        state.isLogged + " state.login:",
        state.login
      );
    },
  },
  getters: {
    getToken: (state) => state.token,
    getUserIsLogged: (state) => state.isLogged,
    checkTokenTime: (state) =>
      state.token
        ? moment
          .duration(moment.unix(state.token.validUntil).diff(moment().valueOf()))
          .minutes() > 5
        : false,
  },
};
