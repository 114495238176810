<template>
  <vue-plyr
    ref="plyrPlayer"
    class="m-0 p-0"
    :style="`width: ${videoObj.width}!important; height: ${videoObj.height}!important`"
  >
    <video
      :style="`width: ${videoObj.width}!important; height: ${videoObj.height}!important`"
      class="m-0 p-0 w-100"
      ref="videoElement"
      :data-poster="videoObj.poster"
      controls
      crossorigin
      playsinline
    >
      <source :src="videoObj.videoSrc" type="video/mp4" />
    </video>
  </vue-plyr>
</template>

<script>
export default {
  props: {
    videoObj: Object,
  },
  emits: ["closeDialog", "confirmData"],
  components: {},
  data() {
    return {};
  },
  methods: {
    pause() {
      if (this.$refs.plyrPlayer) {
        this.$refs.plyrPlayer.player.pause();
      }
    },
    timecode() {
      return this.$refs.videoElement.currentTime;
    },
    gotoMarker(timeInSec) {
      this.$refs.plyrPlayer.player.currentTime = timeInSec;
    },
  },
  mounted() {
    // Vue Plyr
    // https://www.npmjs.com/package/vue-plyr
    // Events
    // https://github.com/sampotts/plyr#events
    this.$refs.plyrPlayer.player.on("pause", () =>
      console.log("-- video pause event fired")
    );
    this.$refs.plyrPlayer.player.on("play", () =>
      console.log("-- video play event fired")
    );
    this.$refs.plyrPlayer.player.markers = {
      enabled: true,
      // points: [{ time: 3600, label: "Test marker" }],
    };
  },
  beforeUnmount() {
    if (this.$refs.plyrPlayer) {
      this.$refs.plyrPlayer.player.destroy();
    }
  },
};
</script>
<style>
.plyr__controls {
  background-color: transparent !important;
}
.plyr__poster {
  background-size: cover !important;
}
</style>